import React from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import _ from 'lodash'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import { BadgeConfig, PayoutStatus } from '../types'

interface PayoutStatusBadgeProps {
  status: PayoutStatus
  style?: StyleProp<ViewStyle>
}

const BADGE_CONFIG: { [key: string]: BadgeConfig } = {
  pending: {
    color: Colors.WARNING[500],
    text: 'To be Transferred',
    icon: 'time-outline',
  },
  transferred: {
    color: Colors.SUCCESS[400],
    text: 'Transferred',
    icon: 'checkmark',
  },
}

export default function PayoutStatusBadge({
  status,
  style,
}: PayoutStatusBadgeProps) {
  return (
    <View
      style={[
        styles.badgeContainer,
        {
          backgroundColor: _.get(BADGE_CONFIG, [status, 'color']),
        },
        style,
      ]}
    >
      <Text style={styles.badgeText} allowFontScaling={false}>
        {_.get(BADGE_CONFIG, [status, 'text'])}
      </Text>
      <Ionicons
        name={_.get(BADGE_CONFIG, [status, 'icon'])}
        size={Metrics.icons.small}
        color={Colors.NEUTRAL[900]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  badgeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Metrics.space,
    paddingVertical: Metrics.smallSpace,
    borderRadius: Metrics.borderRadius,
  },
  badgeText: { ...Fonts.caption1, marginRight: Metrics.smallMargin },
})

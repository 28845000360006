import { TextStyle } from 'react-native'

import Colors from './Colors'

const FontFamilies = {
  regular: { fontFamily: 'Inter-Regular' },
  light: { fontFamily: 'Inter-Light' },
  extraLight: { fontFamily: 'Inter-ExtraLight' },
  medium: { fontFamily: 'Inter-Medium' },
  bold: { fontFamily: 'Inter-Bold' },
  extraBold: { fontFamily: 'Inter-ExtraBold' },
  semiBold: { fontFamily: 'Inter-SemiBold' },
  black: { fontFamily: 'Inter-Black' },
  thin: { fontFamily: 'Inter-Thin' },
}

// Font Families
// ============================================================================

// TODO:
// enable cv05, tnum which are OpenType Font Feature Settings
// * It seems like cv05 cannot be enabled on react native as it seems to be more
// CSS supported than iOS Android.
// tnum seems like it can be enabled via Text style props: fontVariant: 'tabular-nums'

// RedeemSG Typography
// ============================================================================
const DEFAULT_TEXT_COLOR = Colors.NEUTRAL[900]

const fonts: Record<string, TextStyle> = {
  display1: {
    fontSize: 64,
    lineHeight: 72,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.bold,
  },

  display2: {
    fontSize: 40,
    lineHeight: 48,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.medium,
  },

  h1: {
    fontSize: 48,
    lineHeight: 56,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.light,
  },

  h2: {
    fontSize: 28,
    lineHeight: 36,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.bold,
  },

  h3: {
    fontSize: 24,
    lineHeight: 32,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.semiBold,
  },

  h4: {
    fontSize: 20,
    lineHeight: 28,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.semiBold,
  },

  h5: {
    fontSize: 18,
    lineHeight: 24,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.medium,
  },

  subhead1: {
    fontSize: 16,
    lineHeight: 24,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.medium,
  },

  subhead2: {
    fontSize: 14,
    lineHeight: 24,
    fontWeight: '500',
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.medium,
  },

  subhead3: {
    fontSize: 14,
    lineHeight: 24,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.semiBold,
  },

  body1: {
    fontSize: 16,
    lineHeight: 24,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.regular,
  },

  body2: {
    fontSize: 14,
    lineHeight: 24,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.regular,
  },
  caption1: {
    fontSize: 12,
    lineHeight: 16,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.medium,
  },

  caption2: {
    fontSize: 12,
    lineHeight: 16,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.regular,
  },
  legal: {
    fontSize: 10,
    lineHeight: 12,
    color: DEFAULT_TEXT_COLOR,
    ...FontFamilies.regular,
  },
}

export default fonts

import React from 'react'
import { StyleSheet, View, Text } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

const styles = StyleSheet.create({
  footerNoteContainer: {
    margin: Metrics.hugeSpace,
    alignItems: 'center',
  },
  footerNoteHeader: {
    ...Fonts.caption1,
    color: Colors.PRIMARY[500],
    textAlign: 'center',
    marginBottom: Metrics.space,
  },
  footerNoteBody: {
    ...Fonts.caption2,
    color: Colors.NEUTRAL[700],
    marginTop: Metrics.smallSpace,
    textAlign: 'center',
  },
  textUnderline: {
    textDecorationLine: 'underline',
  },
})

const HEADER = `This page shows up to ${99} transactions. As such, the last day’s total and transaction history may be inaccurate.`

// TODO: This is due to truncating at 500 transactions. To remove once fixed
const EXPLANATION = 'This does not affect your payouts. '
const DOWNLOAD_TEXT = 'download a CSV file'

interface TransactionFooterProps {
  onDownloadCsv?: () => void
}

export default function TransactionsFooter({
  onDownloadCsv,
}: TransactionFooterProps) {
  return (
    <View style={styles.footerNoteContainer}>
      <Text style={styles.footerNoteHeader}>{HEADER}</Text>
      <Text style={styles.footerNoteBody}>
        {EXPLANATION}
        {onDownloadCsv && (
          <Text
            onPress={onDownloadCsv}
            style={[styles.footerNoteBody, styles.textUnderline]}
          >
            {DOWNLOAD_TEXT}
          </Text>
        )}
      </Text>
    </View>
  )
}

import {
  BaseToast,
  BaseToastProps,
  ErrorToast,
} from 'react-native-toast-message'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

export const toastConfig = {
  /*
    Overwrite 'success' type,
    by modifying the existing `BaseToast` component
  */
  success: (props: BaseToastProps) => (
    <BaseToast
      {...props}
      style={{
        borderLeftColor: Colors.SUCCESS[500],
        height: '100%',
        width: '95%',
      }}
      contentContainerStyle={{ padding: Metrics.mediumSpace }}
      text1Style={{
        ...Fonts.subhead1,
        color: Colors.PRIMARY[700],
      }}
      text2Style={{
        ...Fonts.caption1,
        paddingTop: Metrics.smallSpace,
        color: Colors.NEUTRAL[700],
      }}
    />
  ),

  info: (props: BaseToastProps) => (
    <BaseToast
      {...props}
      style={{
        borderLeftColor: Colors.PRIMARY[500],
        height: '100%',
        width: '95%',
      }}
      contentContainerStyle={{ padding: Metrics.largeSpace }}
      text1Style={{
        ...Fonts.subhead2,
        color: Colors.STANDARD['BLACK'],
      }}
      text2Style={{
        ...Fonts.caption1,
        paddingTop: Metrics.smallSpace,
        color: Colors.NEUTRAL[700],
      }}
    />
  ),
  /*
  Overwrite 'error' type,
  by modifying the existing `ErrorToast` component
*/
  error: (props: BaseToastProps) => (
    <ErrorToast
      {...props}
      text1Style={{
        fontSize: 17,
      }}
      text2Style={{
        fontSize: 15,
      }}
    />
  ),
  /*
  Or create a completely new type - `tomatoToast`,
  building the layout from scratch.

  I can consume any custom `props` I want.
  They will be passed when calling the `show` method (see below)

  tomatoToast: ({ text1, props }) => (
    <View style={{ height: 60, width: '100%', backgroundColor: 'tomato' }}>
      <Text>{text1}</Text>
      <Text>{props.uuid}</Text>
    </View>
  ),

  */
}

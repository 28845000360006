import React, { ReactNode } from 'react'
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native'

import Colors from 'theme/Colors'
import Metrics from 'theme/Metrics'

import PlainCard from 'components/Cards/PlainCard'

interface HeaderBlockFloatCardProps {
  style: StyleProp<ViewStyle>
  children: ReactNode
  onLayout: ((event: LayoutChangeEvent) => void) | undefined
}

export default function HeaderBlockFloatCard({
  style,
  children,
  onLayout,
}: HeaderBlockFloatCardProps) {
  return (
    <PlainCard style={[styles.container, style]} onLayout={onLayout}>
      {children}
    </PlainCard>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.STANDARD.WHITE,
    position: 'absolute',
    left: 0,
    right: 0,
    marginHorizontal: Metrics.largeSpace,
  },
})

import React from 'react'
import { StyleSheet, ScrollView } from 'react-native'

import * as Linking from 'expo-linking'
import Toast from 'react-native-toast-message'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import OgpLogo from 'assets/svg/ogp-logo'
import SettingsIconTextRow from 'components//Rows/SettingsIconTextRow'
import PressableOpacity from 'components/Buttons/PressableOpacity'
import PlainCard from 'components/Cards/PlainCard'
import HeaderBlock from 'components/HeaderBlock'
import TransparentGradient from 'components/Misc/TransparentGradient'
import { MARKETING_LINKS, SUPPORT_LINKS } from 'constants/links'
import { copyMagicLink } from 'helpers/utils'
import useAuth from 'hooks/useAuth'
import useMerchantDetails from 'hooks/useMerchantDetails'
import ScreenTemplate from 'templates/ScreenTemplate'
import { getMerchantName } from 'constants/hardcode'

const HEADER_TEXT = 'Settings'
const COPY_WALLET_CODE = 'Copy Wallet Link'
const FAQ = 'FAQ'
const CONTACT_US = 'Contact Us'
const LOGOUT = 'Logout'
const TERMS_OF_USE = 'Terms of Use'

export default function SettingsScreen() {
  const { signOut, merchantAddress } = useAuth()

  const { data: merchantData } = useMerchantDetails({
    address: merchantAddress,
  })

  const onClickCopy = async () => {
    await copyMagicLink(merchantAddress)
    Toast.show({
      type: 'info',
      text1: 'Magic link is copied to clipboard',
    })
  }

  return (
    <ScreenTemplate>
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <HeaderBlock
          headerText={HEADER_TEXT}
          merchantName={getMerchantName(
            merchantAddress,
            merchantData?.shopName,
          )}
          headerStyle={styles.header}
        />
        <PlainCard style={styles.card}>
          <SettingsIconTextRow
            iconName="help-circle-outline"
            onPress={() => Linking.openURL(SUPPORT_LINKS.faq)}
          >
            {FAQ}
          </SettingsIconTextRow>
          <SettingsIconTextRow
            iconName="mail-open-outline"
            onPress={() => Linking.openURL(SUPPORT_LINKS.contactUs)}
          >
            {CONTACT_US}
          </SettingsIconTextRow>

          <SettingsIconTextRow
            iconName="newspaper-outline"
            onPress={() => Linking.openURL(SUPPORT_LINKS.termsOfUse)}
          >
            {TERMS_OF_USE}
          </SettingsIconTextRow>
        </PlainCard>

        <PlainCard style={styles.card}>
          <SettingsIconTextRow iconName="wallet-outline" onPress={onClickCopy}>
            {COPY_WALLET_CODE}
          </SettingsIconTextRow>
          <SettingsIconTextRow
            iconName="arrow-back-circle-outline"
            onPress={signOut}
          >
            {LOGOUT}
          </SettingsIconTextRow>
        </PlainCard>
        <PressableOpacity onPress={() => Linking.openURL(MARKETING_LINKS.ogp)}>
          <OgpLogo style={styles.logo} />
        </PressableOpacity>
      </ScrollView>

      <TransparentGradient
        height={Metrics.gradientHeight}
        horizontalPadding={Metrics.largeSpace}
      />
    </ScreenTemplate>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingBottom: Metrics.hugeSpace,
  },
  card: {
    marginTop: Metrics.largeSpace,
    marginHorizontal: Metrics.largeSpace,
  },
  contentContainerStyle: {
    paddingBottom: Metrics.hugeSpace,
  },
  textLinkContainer: {
    paddingVertical: Metrics.hugeSpace,
  },
  textLink: {
    ...Fonts.caption1,
    color: Colors.PRIMARY[500],
    marginTop: Metrics.space,
    marginLeft: Metrics.largeSpace,
  },
  logo: {
    marginLeft: Metrics.largeSpace,
    marginVertical: Metrics.hugeSpace,
  },
})

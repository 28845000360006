import AsyncStorage from '@react-native-async-storage/async-storage'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { noopStorage } from '@wagmi/core'
import { configureChains, createClient, createStorage } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'

import { ALCHEMY_API_KEY, CHAIN, INFURA_API_KEY } from 'constants/config'

const asyncStoragePersister = createAsyncStoragePersister({
  storage: AsyncStorage,
})

const fallbackInfuraProvider = INFURA_API_KEY
  ? infuraProvider({ apiKey: INFURA_API_KEY })
  : infuraProvider()

const fallbackAlchemyProvider = ALCHEMY_API_KEY
  ? alchemyProvider({ apiKey: ALCHEMY_API_KEY })
  : publicProvider()

const { provider } = configureChains(
  [CHAIN],
  [
    jsonRpcProvider({
      // NOTE: Ankr providers have an unconventional naming scheme for network name
      rpc: (chain) => ({
        http: `https://rpc.ankr.com/${
          chain.id === 137 ? 'polygon' : 'polygon_mumbai'
        }`,
      }),
    }),
    fallbackInfuraProvider,
    fallbackAlchemyProvider,
  ],
  { stallTimeout: 4000 },
)

export const globalClient = createClient({
  autoConnect: true,
  provider,
  persister: asyncStoragePersister,
  storage: createStorage({ storage: noopStorage }),
})

import React, { useState } from 'react'

import _ from 'lodash'
import moment from 'moment-timezone'
import Toast from 'react-native-toast-message'

import SmartphoneImage from 'assets/svg/smartphone'
import ImageTextView from 'components/Views/ImageTextView'
import { DSGD_ADDRESS, PBM_ADDRESS } from 'constants/config'
import { formatBigNumberToDisplay } from 'helpers/utils'
import useAuth from 'hooks/useAuth'
import useMerchantDetails from 'hooks/useMerchantDetails'
import useTransferEventViaApi from 'hooks/useTransferEventViaApi'
import ScreenTemplate from 'templates/ScreenTemplate'
import dsgd from 'web3/abi/dsgd.json'

import TransactionsHeader from './components/TransactionHeader'
import TransactionsList from './components/TransactionList'
import { useSectionalizeTransactions } from './hooks/useSectionalizedTransactions'
import { getMerchantName } from 'constants/hardcode'

const HEADER_LABEL_TEXT = 'Transactions'
const HEADER_SHOP_CARD_TEXT = 'Total received by entire shop today'
const HEADER_USER_CARD_TEXT = 'Deprecating feature'
const LEFT_BUTTON_TEXT = 'ENTIRE SHOP'
const RIGHT_BUTTON_TEXT = 'YOU'
const NO_TRANSACTIONS_PRIMARY_TEXT =
  'Vouchers you have received will appear here'
const LOADING_TRANSACTIONS_PRIMARY_TEXT = 'Loading your transactions...'

// TODO: Update text shown for TitleValue to be more concise
// TODO: Look at state where we fail to fetch transactions
export default function TransactionsScreen() {
  const { merchantAddress } = useAuth()
  const { data: merchantData } = useMerchantDetails({
    address: merchantAddress,
  })

  const {
    data: transactions,
    refetch,
    dataUpdatedAt,
    isLoading,
  } = useTransferEventViaApi({
    key: 'txEvents',
    toAddress: merchantAddress,
    fromAddress: PBM_ADDRESS,
    abi: dsgd.abi,
    contractAddress: DSGD_ADDRESS,
    startBlock: 7460420,
    eventListener: (e) =>
      Toast.show({
        type: 'success',
        text1: `Received DSGD $${formatBigNumberToDisplay(e[2])}`,
        text2: `See the transaction in the transaction page`,
      }),
  })

  const { groupedTransactions, totalValueToday } =
    useSectionalizeTransactions(transactions)

  const [isShopView, setIsShopView] = useState(true)

  const header = (
    <TransactionsHeader
      lastUpdatedAt={moment(dataUpdatedAt).calendar()}
      merchantName={getMerchantName(merchantAddress, merchantData?.shopName)}
      activeButton={isShopView ? 'active_button_left' : 'active_button_right'}
      onLeftButtonPress={() => setIsShopView(true)}
      labelText={HEADER_LABEL_TEXT}
      leftButtonText={LEFT_BUTTON_TEXT}
      rightButtonText={RIGHT_BUTTON_TEXT}
      titleCardText={isShopView ? HEADER_SHOP_CARD_TEXT : HEADER_USER_CARD_TEXT}
      titleCardValue={totalValueToday}
      onRefetch={refetch}
    />
  )
  return (
    <ScreenTemplate>
      {
        // When no transactions, show illustration for no transactions, else show transactions
        _.isEmpty(transactions) ? (
          <>
            {header}
            <ImageTextView ImageComponent={SmartphoneImage}>
              {isLoading
                ? LOADING_TRANSACTIONS_PRIMARY_TEXT
                : NO_TRANSACTIONS_PRIMARY_TEXT}
            </ImageTextView>
          </>
        ) : (
          <TransactionsList sections={groupedTransactions} header={header} />
        )
      }
    </ScreenTemplate>
  )
}

import { useMemo } from 'react'

import { BigNumber } from 'ethers'
import _ from 'lodash'
import moment from 'moment-timezone'

import { TransferEventWithTimestamp } from 'typings/transaction'

import { formatBigNumberToDisplay } from 'helpers/utils'

const DATE_FORMAT = 'D MMMM YYYY'

const sectionalizeTransactions = (
  pastTransactions?: TransferEventWithTimestamp[],
) => {
  const groupedTransactions = _(pastTransactions)
    .groupBy((tx) => moment(tx.timestamp).format(DATE_FORMAT))
    .map((txGroup, txGroupDate) => ({
      key: txGroupDate,
      totalValue: formatBigNumberToDisplay(
        _.reduce(
          txGroup,
          (res, tx) => {
            return res.add(tx.txValue)
          },
          BigNumber.from(0),
        ),
      ),
      data: _.map(txGroup, (tx) => ({
        id: tx.transactionHash,
        verifierName: 'PBM Contract',
        value: formatBigNumberToDisplay(tx.txValue),
        createdAt: tx.timestamp,
      })),
    }))
    .value()

  const currentDateValue =
    _.find(
      groupedTransactions,
      (txGroup) => txGroup.key === moment().format(DATE_FORMAT),
    )?.totalValue || '0'

  return {
    groupedTransactions,
    totalValueToday: currentDateValue,
  }
}

export function useSectionalizeTransactions(
  transactions?: TransferEventWithTimestamp[],
) {
  return useMemo(() => sectionalizeTransactions(transactions), [transactions])
}

import { BigNumber, FixedNumber } from 'ethers'
import {
  formatUnits,
  parseUnits,
  isAddress as ethersIsAddress,
  getAddress as ethersGetAddress,
} from 'ethers/lib/utils'
import * as Clipboard from 'expo-clipboard'

import { BASE_URL, PBM_DECIMALS } from 'constants/config'

export function formatBigNumberToString(bigNumber: BigNumber) {
  return formatUnits(bigNumber, PBM_DECIMALS)
}

export function formatBigNumberToDisplay(bigNumber: BigNumber) {
  return FixedNumber.fromValue(bigNumber, PBM_DECIMALS).round(2).toString()
}

export function parseToBigNumber(value: string) {
  return parseUnits(value, PBM_DECIMALS)
}

export function isAddress(address: string) {
  return ethersIsAddress(address)
}

export function getChecksumedAddress(address: string) {
  return ethersGetAddress(address)
}

// Allows for truncation to follow MetaMask's style
// Note: This is not responsive. It is meant to be for user simplification
export function truncateIdentifier(id: string) {
  return id.substring(0, 6) + '....' + id.substring(id.length - 4) || ''
}

export async function copyMagicLink(address: string) {
  await Clipboard.setStringAsync(`${BASE_URL}/login/${address}`)
}

import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import humps from 'humps'

// Constants
import { API_BASE_URL } from 'constants/config'

// Create the api service which operational needs calls
const ApiService = axios.create({
  baseURL: `${API_BASE_URL}/v1`,
  transformRequest: [
    (data) => (data ? humps.decamelizeKeys(data) : data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],

  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => (data ? humps.camelizeKeys(data) : data),
  ],
})

export default ApiService

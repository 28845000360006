import React from 'react'
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native'

export interface PressableOpacityProps extends PressableProps {
  // Stricter Type for defined style
  style?: StyleProp<ViewStyle>
}

// This wraps the Pressable component to provide opacity feedback
export default function PressableOpacity(props: PressableOpacityProps) {
  const { children, style, ...rest } = props
  return (
    <Pressable
      style={({ pressed }) => [style, { opacity: pressed ? 0.5 : 1 }]}
      {...rest}
    >
      {children}
    </Pressable>
  )
}

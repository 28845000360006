import AsyncStorage from '@react-native-async-storage/async-storage'

async function getItem(key: string): Promise<string | null> {
  return AsyncStorage.getItem(key)
}

async function setItem(key: string, value: string): Promise<void> {
  return AsyncStorage.setItem(key, value)
}

async function removeItem(key: string): Promise<void> {
  return AsyncStorage.removeItem(key)
}

export const store = {
  getItem,
  setItem,
  removeItem,
}

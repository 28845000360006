import React, { ReactElement, useState } from 'react'
import { StyleSheet, View, SectionList } from 'react-native'

import { Entypo } from '@expo/vector-icons'
import _ from 'lodash'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'
import { TransactionSection } from 'typings/transaction'

import TextButton from 'components/Buttons/TextButton'
import TransactionCard from 'components/Cards/TransactionCard'
import TransactionSectionHeaderCard from 'components/Cards/TransactionSectionHeaderCard'
import TransparentGradient from 'components/Misc/TransparentGradient'

import TransactionsFooter from './TransactionFooter'

const SECTION_COLLAPSED_TEXT = 'Show all transactions'
const SECTION_EXPANDED_TEXT = 'Show less transactions'
const NUM_ROWS_COLLAPSED = 20

interface TransactionListProps {
  sections: TransactionSection[]
  header: ReactElement
  onDownloadCsv?: () => void
}

export default function TransactionsList({
  sections,
  header,
}: TransactionListProps) {
  // Map of whether a section is in expanded state
  const [sectionExpandedMap, setSectionExpandedMap] = useState<
    Record<string, boolean>
  >({})

  function toggleSectionExpanded(index: string) {
    setSectionExpandedMap((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  return (
    <View style={styles.containingView}>
      <SectionList
        sections={sections}
        ListHeaderComponent={header}
        ListFooterComponent={<TransactionsFooter />}
        stickySectionHeadersEnabled={false}
        contentContainerStyle={styles.listContentContainer}
        renderSectionHeader={({ section: { key, totalValue } }) => (
          <TransactionSectionHeaderCard title={key!} totalValue={totalValue} />
        )}
        renderItem={({ item, index, section }) => {
          // If index >= NUM_ROWS_COLLAPSED, do not render if sectionExpandedMap false
          if (index >= NUM_ROWS_COLLAPSED && !sectionExpandedMap[section.key]) {
            return null
          }
          return <TransactionCard transaction={item} />
        }}
        renderSectionFooter={({ section }) => {
          // Three different states to render
          // 1. Length of section more than 5 and sectionExpandedMap false, show option to expand
          // 2. Length of section more than 5 and sectionExpandedMap true, show option to collapse
          // 3. Length of section less than or equal to 5, return null
          const sectionSize = _.size(section.data)
          if (
            sectionSize > NUM_ROWS_COLLAPSED &&
            !sectionExpandedMap[section.key]
          ) {
            return (
              <TextButton
                onPress={() => toggleSectionExpanded(section.key)}
                style={styles.sectionFooterContainer}
                textStyle={styles.sectionFooterText}
              >
                {`${SECTION_COLLAPSED_TEXT} (${
                  sectionSize - NUM_ROWS_COLLAPSED
                }) `}
                <Entypo name="chevron-small-down" size={24} color="black" />
              </TextButton>
            )
          }
          if (
            sectionSize > NUM_ROWS_COLLAPSED &&
            sectionExpandedMap[section.key]
          ) {
            return (
              <TextButton
                onPress={() => toggleSectionExpanded(section.key)}
                style={styles.sectionFooterContainer}
                textStyle={styles.sectionFooterText}
              >
                {SECTION_EXPANDED_TEXT}
                <Entypo name="chevron-small-up" size={24} color="black" />
              </TextButton>
            )
          }
          return null
        }}
      />
      <TransparentGradient
        height={Metrics.gradientHeight}
        horizontalPadding={Metrics.largeSpace}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containingView: {
    flex: 1,
  },
  sectionFooterContainer: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: Colors.STANDARD.WHITE,
    padding: Metrics.mediumSpace,
    marginHorizontal: Metrics.largeMargin,
  },
  sectionFooterText: {
    ...Fonts.subhead2,
    color: Colors.PRIMARY[500],
    textAlign: 'left',
  },
  listContentContainer: {
    paddingBottom: Metrics.hugeSpace,
  },
})

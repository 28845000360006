import { useEffect, useState } from 'react'

import { Ionicons } from '@expo/vector-icons'
import * as Font from 'expo-font'

export const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'Inter-Regular': require('assets/fonts/Inter-Regular.ttf'),
          'Inter-Light': require('assets/fonts/Inter-Light.ttf'),
          'Inter-ExtraLight': require('assets/fonts/Inter-ExtraLight.ttf'),
          'Inter-Medium': require('assets/fonts/Inter-Medium.ttf'),
          'Inter-Bold': require('assets/fonts/Inter-Bold.ttf'),
          'Inter-ExtraBold': require('assets/fonts/Inter-ExtraBold.ttf'),
          'Inter-SemiBold': require('assets/fonts/Inter-SemiBold.ttf'),
          'Inter-Black': require('assets/fonts/Inter-Black.ttf'),
          'Inter-Thin': require('assets/fonts/Inter-Thin.ttf'),
        })
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  return isLoadingComplete
}

import * as React from 'react'
import Svg, {
  Path, G, Defs, ClipPath,
} from 'react-native-svg'

function SvgComponent(props) {
  return (
    <Svg
      width={64}
      height={64}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <G clipPath='url(#prefix__clip0)'>
        <Path d='M5.333 17.067h53.334v41.45H5.332v-41.45z' fill='#fff' />
        <Path d='M5.333 17.067h50.134v41.45H5.334v-41.45z' fill='#DFE1F2' />
        <Path
          d='M61.867 14.64v4.379a4.39 4.39 0 01-3.85 4.428 4.267 4.267 0 01-4.683-4.247 4.267 4.267 0 01-8.534 0 4.267 4.267 0 11-8.533 0 4.267 4.267 0 11-8.534 0 4.267 4.267 0 11-8.533 0 4.267 4.267 0 01-8.533 0 4.264 4.264 0 01-4.684 4.247 4.39 4.39 0 01-3.85-4.428v-4.38c0-.499.176-.982.495-1.365l4.839-5.807h49.066l4.84 5.806c.319.384.494.867.494 1.366z'
          fill='#fff'
        />
        <Path
          d='M9.6 1.067h44.8c1.178 0 2.133.955 2.133 2.133v4.267H7.467V3.2c0-1.178.955-2.133 2.133-2.133z'
          fill='#fff'
        />
        <Path
          d='M9.6 1.067h40.533c1.178 0 2.134.955 2.134 2.133v4.267h-44.8V3.2c0-1.178.955-2.133 2.133-2.133zM32 28.8v13.866a1.07 1.07 0 01-1.067 1.067h-19.2a1.07 1.07 0 01-.992-.682.92.92 0 01-.075-.385V28.8a1.07 1.07 0 011.067-1.067h19.2c.44.003.833.273.992.683A.915.915 0 0132 28.8z'
          fill='#fff'
        />
        <Path
          d='M28.8 28.8v13.866a1.07 1.07 0 01-1.067 1.067h-16a1.07 1.07 0 01-.992-.682.92.92 0 01-.075-.385V28.8a1.07 1.07 0 011.067-1.067h16c.44.003.833.273.992.683a.9.9 0 01.074.384zM61.717 13.867H2.283c.077-.218.193-.42.341-.598l4.843-5.802h49.066l4.843 5.803c.148.177.264.379.341.597z'
          fill='#fff'
        />
        <Path
          d='M56.533 13.867H3.2c.069-.215.172-.417.306-.598l4.346-5.802h44.03l4.345 5.803c.134.18.238.382.306.597zM53.334 28.8v28.8H38.4V28.8a1.07 1.07 0 011.067-1.067h12.8a1.07 1.07 0 011.066 1.067z'
          fill='#fff'
        />
        <Path
          d='M50.133 28.8v28.8H38.4V28.8a1.07 1.07 0 011.067-1.067h9.6a1.069 1.069 0 011.066 1.067z'
          fill='#fff'
        />
        <Path
          d='M43.733 32H48c.589 0 1.067.477 1.067 1.067v4.267c0 .588-.478 1.066-1.067 1.066h-4.267c-.589 0-1.066-.478-1.066-1.066v-4.267c0-.59.477-1.067 1.066-1.067z'
          fill='#fff'
        />
        <Path
          d='M43.733 32H44.8c.59 0 1.067.477 1.067 1.067v4.267c0 .588-.478 1.066-1.067 1.066h-1.067c-.589 0-1.066-.478-1.066-1.066v-4.267c0-.59.477-1.067 1.066-1.067zM3.2 56.533h57.6c1.178 0 2.133.955 2.133 2.133v3.2c0 .59-.477 1.067-1.066 1.067H2.133a1.067 1.067 0 01-1.066-1.066v-3.2c0-1.179.955-2.134 2.133-2.134z'
          fill='#fff'
        />
        <Path
          d='M3.2 56.533h53.333c1.178 0 2.133.955 2.133 2.133v3.2c0 .59-.477 1.067-1.066 1.067H2.133a1.067 1.067 0 01-1.066-1.066v-3.2c0-1.179.955-2.134 2.133-2.134z'
          fill='#fff'
        />
        <Path
          d='M2.133 62.933h3.2c-.589 0-1.066-.477-1.066-1.066v-3.2c0-1.179.955-2.134 2.133-2.134h2.133V23.318a4.432 4.432 0 01-3.2-4.3V14.64c.003-.315.075-.625.212-.908.072-.166.165-.32.28-.461l4.841-5.803V3.2c0-1.178.956-2.133 2.134-2.133H9.6A2.133 2.133 0 007.467 3.2v4.267L2.624 13.27c-.113.14-.208.295-.28.461a2.126 2.126 0 00-.21.908v4.38a4.432 4.432 0 003.2 4.3v33.214H3.2a2.133 2.133 0 00-2.133 2.133v3.2c0 .59.477 1.067 1.066 1.067z'
          fill='#fff'
        />
        <Path
          d='M61.867 64H2.133A2.135 2.135 0 010 61.867v-3.2a3.204 3.204 0 013.2-3.2h57.6a3.204 3.204 0 013.2 3.2v3.2A2.135 2.135 0 0161.867 64zM3.2 57.6c-.589 0-1.066.478-1.067 1.066v3.2h59.735l-.001-3.2A1.068 1.068 0 0060.8 57.6H3.2z'
          fill='#505798'
        />
        <Path d='M6.4 23.317v33.216H4.267V23.317H6.4z' fill='#fff' />
        <Path
          d='M5.333 28.65A1.07 1.07 0 006.4 27.585v-4.267H4.267v4.267a1.07 1.07 0 001.066 1.067zM4.267 56.533H6.4V36.117a1.066 1.066 0 00-2.133 0v20.416zM6.4 31.85a1.123 1.123 0 00-.31-.757l-.16-.128a.815.815 0 00-.191-.096.69.69 0 00-.192-.064 1.565 1.565 0 00-.214-.02 1.119 1.119 0 00-.757.308 1.052 1.052 0 00.352 1.739 1.056 1.056 0 001.472-.981z'
          fill='#505798'
        />
        <Path
          d='M58.666 57.6H5.334a1.067 1.067 0 110-2.133H57.6v-32.15a1.067 1.067 0 112.133 0v33.216c0 .59-.477 1.067-1.066 1.067z'
          fill='#505798'
        />
        <Path
          d='M49.067 24.533a5.33 5.33 0 01-4.267-2.136 5.328 5.328 0 01-8.534 0 5.328 5.328 0 01-8.533 0 5.329 5.329 0 01-8.533 0 5.329 5.329 0 01-8.532 0 5.324 5.324 0 01-4.786 2.112 5.473 5.473 0 01-4.815-5.49v-4.38c0-.748.262-1.473.74-2.048l4.84-5.807c.203-.243.503-.384.82-.384h49.066c.317 0 .617.14.82.384l4.839 5.807c.478.575.74 1.3.74 2.048v4.38a5.473 5.473 0 01-4.815 5.49 5.323 5.323 0 01-4.785-2.111 5.327 5.327 0 01-4.265 2.135zm-41.1-16l-4.52 5.423a1.07 1.07 0 00-.247.683v4.38a3.324 3.324 0 002.883 3.366 3.162 3.162 0 002.47-.816A3.21 3.21 0 009.6 19.2a1.067 1.067 0 112.133 0 3.2 3.2 0 006.4 0 1.067 1.067 0 112.133 0 3.2 3.2 0 006.4 0 1.067 1.067 0 112.134 0 3.2 3.2 0 006.4 0 1.067 1.067 0 112.133 0 3.2 3.2 0 006.4 0 1.067 1.067 0 112.134 0 3.2 3.2 0 006.4 0 1.067 1.067 0 112.133 0c0 .902.381 1.76 1.047 2.368.668.613 1.567.91 2.47.817a3.324 3.324 0 002.883-3.366v-4.38c0-.25-.088-.491-.247-.682l-4.52-5.424H7.966z'
          fill='#505798'
        />
        <Path
          d='M56.533 8.533H7.467c-.59 0-1.067-.477-1.067-1.066V3.2A3.204 3.204 0 019.6 0h44.8a3.204 3.204 0 013.2 3.2v4.267c0 .589-.477 1.066-1.067 1.066zm-48-2.133h46.934V3.2A1.069 1.069 0 0054.4 2.133H9.6A1.069 1.069 0 008.533 3.2v3.2zM49.067 14.933H2.283a1.067 1.067 0 110-2.133h46.784a1.067 1.067 0 010 2.133zM61.717 14.933h-8.383a1.067 1.067 0 110-2.133h8.383a1.067 1.067 0 110 2.133zM53.334 20.267c-.59 0-1.067-.478-1.067-1.067v-1.067a1.067 1.067 0 012.133 0V19.2c0 .59-.478 1.067-1.066 1.067zM44.8 20.267a1.066 1.066 0 01-1.067-1.067v-1.067a1.066 1.066 0 012.133 0V19.2c0 .59-.477 1.067-1.067 1.067zM36.266 20.267A1.066 1.066 0 0135.2 19.2v-1.067a1.066 1.066 0 012.133 0V19.2c0 .59-.477 1.067-1.067 1.067zM27.733 20.267c-.589 0-1.067-.478-1.067-1.067v-1.067a1.067 1.067 0 012.134 0V19.2c0 .59-.478 1.067-1.067 1.067zM19.2 20.267c-.59 0-1.067-.478-1.067-1.067v-1.067a1.067 1.067 0 012.134 0V19.2c0 .59-.478 1.067-1.067 1.067zM10.666 20.267A1.066 1.066 0 019.6 19.2v-1.067a1.066 1.066 0 012.133 0V19.2c0 .59-.477 1.067-1.067 1.067zM30.933 44.8h-19.2A2.135 2.135 0 019.6 42.667V28.8a2.136 2.136 0 012.133-2.134h19.2a2.136 2.136 0 012.134 2.134v13.866a2.136 2.136 0 01-2.134 2.134zm-19.2-16v13.866h19.202L30.933 28.8h-19.2zM53.334 57.6H38.4a1.067 1.067 0 01-1.066-1.067V28.8a2.136 2.136 0 012.133-2.134h12.8A2.136 2.136 0 0154.4 28.8v27.733c0 .59-.478 1.067-1.066 1.067zm-13.867-2.133h12.8V28.8h-12.8v26.667z'
          fill='#505798'
        />
        <Path
          d='M48 39.467h-4.267a2.136 2.136 0 01-2.133-2.133v-4.267a2.136 2.136 0 012.133-2.134H48a2.136 2.136 0 012.133 2.134v4.267A2.136 2.136 0 0148 39.467zm-4.267-6.4v4.268L48 37.333v-4.266h-4.267zM43.733 43.733h-1.066a1.067 1.067 0 010-2.133h1.066a1.066 1.066 0 010 2.133zM10.666 42.666a1.066 1.066 0 01-.726-1.848l4.267-3.962a1.067 1.067 0 111.452 1.564l-4.267 3.962a1.065 1.065 0 01-.726.285zM18.133 35.733a1.067 1.067 0 01-.726-1.848l7.467-6.933a1.067 1.067 0 011.452 1.563l-7.467 6.933a1.065 1.065 0 01-.726.285zM17.067 44.8a1.067 1.067 0 01-.726-1.848l9.6-8.915a1.066 1.066 0 011.451 1.564l-9.6 8.914a1.063 1.063 0 01-.725.285zM29.703 33.067a1.067 1.067 0 01-.727-1.849l2.298-2.133a1.067 1.067 0 011.452 1.563l-2.297 2.134a1.065 1.065 0 01-.726.285z'
          fill='#505798'
        />
      </G>
      <Defs>
        <ClipPath id='prefix__clip0'>
          <Path fill='#fff' d='M0 0h64v64H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SvgComponent

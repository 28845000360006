import React from 'react'
import { View, StyleSheet } from 'react-native'

import Colors from 'theme/Colors'
import Metrics from 'theme/Metrics'

import TitleValueCard from 'components/Cards/TitleValueCard'

import PayoutStatusBadge from './PayoutStatusBadge'

interface PayoutHeaderCardProps {
  value?: string
}

export default function PayoutHeaderCard({ value }: PayoutHeaderCardProps) {
  return (
    <View style={styles.headerBlockView}>
      <TitleValueCard
        value={value || '-'}
        cardStyle={styles.cardStyle}
        textStyle={styles.textStyle}
      />
      <PayoutStatusBadge status="pending" />
    </View>
  )
}

const styles = StyleSheet.create({
  headerBlockView: {
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    padding: Metrics.largeSpace,
    marginBottom: Metrics.baseMargin,
    backgroundColor: Colors.PRIMARY[900],
  },
  cardStyle: {
    backgroundColor: Colors.PRIMARY[900],
    // Clear the margins and paddings set in titleValueCard
    // so that the settings can be managed here in the parent component
    margin: 0,
    padding: 0,
    marginRight: Metrics.baseMargin,
  },
  textStyle: {
    color: Colors.STANDARD.WHITE,
  },
})

import { STORAGE_KEYS } from 'constants/storageKeys'
import { store } from 'helpers/storage'

const { USER_ADDRESS } = STORAGE_KEYS

// Current iteration uses address as a token
// TODO: Consider revisiting a proper token exchange mechanism in the future
export type Address = string

export function setAddress(address: Address) {
  return store.setItem(USER_ADDRESS, address)
}

export async function getAddress(): Promise<Address | undefined> {
  const address = await store.getItem(USER_ADDRESS)
  if (!address) {
    return
  }
  return address
}

export async function deleteAddress() {
  const address = await store.getItem(USER_ADDRESS)

  if (!address) return

  return store.removeItem(USER_ADDRESS)
}

import React, { ReactNode } from 'react'
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'

import Colors from 'theme/Colors'

interface PlainCardProps {
  style: StyleProp<ViewStyle>
  children: ReactNode
  onLayout?: ((event: LayoutChangeEvent) => void) | undefined
  isShadow?: boolean
}

export default function PlainCard({
  style,
  isShadow = true,
  children,
  onLayout,
}: PlainCardProps) {
  return (
    <View
      style={[styles.container, isShadow ? SHADOW : null, style]}
      onLayout={onLayout}
    >
      {children}
    </View>
  )
}

// Styling related constants
const SHADOW = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.32,
  shadowRadius: 5.46,
  elevation: 9,
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    backgroundColor: Colors.STANDARD.WHITE,
  },
})

import React, { ReactNode, useState } from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import HeaderBlockFloatCard from 'components/Cards/HeaderBlockFloatCard'
import HeaderIconTextRow, { ICON_TYPE } from 'components/Rows/HeaderIconTextRow'
import VersionDisplay from 'components/VersionDisplay'

interface HeaderBlockProps {
  headerText: string
  merchantName?: string
  cardStyle?: StyleProp<ViewStyle>
  headerStyle?: StyleProp<ViewStyle>
  children?: ReactNode
  innerElement?: ReactNode
  username?: string
}

export default function HeaderBlock({
  headerText,
  merchantName,
  username,
  cardStyle,
  headerStyle,
  children,
  innerElement,
}: HeaderBlockProps) {
  const [cardHeight, setCardHeight] = useState(0)
  const halfCardHeight = cardHeight && cardHeight / 2

  return (
    <View
      style={[
        styles.container,
        headerStyle,
        {
          ...(halfCardHeight &&
            children && {
              paddingBottom: halfCardHeight,
              marginBottom: halfCardHeight,
            }),
        },
      ]}
    >
      <VersionDisplay textColor={Colors.STANDARD.WHITE} />

      <Text style={styles.headerText} allowFontScaling={false}>
        {headerText}
      </Text>
      <View style={styles.rowContainer}>
        {!!merchantName && (
          <HeaderIconTextRow
            iconType={ICON_TYPE.MERCHANT}
            allowFontScaling={false}
          >
            {merchantName}
          </HeaderIconTextRow>
        )}
        {!!username && (
          <HeaderIconTextRow iconType={ICON_TYPE.USER} allowFontScaling={false}>
            {username}
          </HeaderIconTextRow>
        )}
      </View>
      {innerElement}
      {children && (
        <HeaderBlockFloatCard
          style={[
            {
              ...(halfCardHeight && { bottom: -halfCardHeight }),
            },
            cardStyle,
          ]}
          onLayout={({ nativeEvent }) =>
            setCardHeight(nativeEvent.layout.height)
          }
        >
          {children}
        </HeaderBlockFloatCard>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.PRIMARY[800],
    paddingHorizontal: Metrics.largeSpace,
    paddingTop: Metrics.hugeSpace,
  },
  rowContainer: {
    marginVertical: Metrics.space,
  },
  headerText: {
    ...Fonts.h2,
    color: Colors.STANDARD.WHITE,
  },
})

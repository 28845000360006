export const getMerchantName = (address: string, name?: string | null) => {
  if (name) {
    return name
  }

  if (address.toLowerCase() === '0x3ac152906d324df0a6dae5b649c075d6c722535e') {
    return 'Central Charity Agency'
  }

  return 'NO_SHOP_NAME'
}

import * as React from 'react'
import Svg, {
  Path, G, Defs, ClipPath,
} from 'react-native-svg'

function SvgComponent(props) {
  return (
    <Svg
      width={84}
      height={84}
      fill='none'
      xmlns='http://www.w3.org/2000/Svg'
      {...props}
    >
      <G clipPath='url(#prefix__clip0)'>
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M65.8 71.4v7a4.2 4.2 0 01-4.2 4.2H22.4a4.2 4.2 0 01-4.2-4.2v-7a4.2 4.2 0 004.2 4.2h39.2a4.2 4.2 0 004.2-4.2z'
          fill='#DFE1F2'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.6 75.6v2.8a4.2 4.2 0 01-4.2 4.2h4.2a4.2 4.2 0 004.2-4.2v-7a4.2 4.2 0 01-4.2 4.2z'
          fill='#DFE1F2'
        />
        <Path
          d='M61.6 1.4H22.4a4.2 4.2 0 00-4.2 4.2v65.8a4.2 4.2 0 004.2 4.2h39.2a4.2 4.2 0 004.2-4.2V5.6a4.2 4.2 0 00-4.2-4.2z'
          fill='#fff'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.6 1.4h-4.2a4.2 4.2 0 014.2 4.2v65.8a4.2 4.2 0 01-4.2 4.2h4.2a4.2 4.2 0 004.2-4.2V5.6a4.2 4.2 0 00-4.2-4.2z'
          fill='#DFE1F2'
        />
        <Path
          d='M35 29.4h-4.2a1.4 1.4 0 00-1.4 1.4V35a1.4 1.4 0 001.4 1.4H35a1.4 1.4 0 001.4-1.4v-4.2a1.4 1.4 0 00-1.4-1.4zM53.2 29.4H49a1.4 1.4 0 00-1.4 1.4V35a1.4 1.4 0 001.4 1.4h4.2a1.4 1.4 0 001.4-1.4v-4.2a1.4 1.4 0 00-1.4-1.4zM35 47.6h-4.2a1.4 1.4 0 00-1.4 1.4v4.2a1.4 1.4 0 001.4 1.4H35a1.4 1.4 0 001.4-1.4V49a1.4 1.4 0 00-1.4-1.4zM53.2 47.6H49a1.4 1.4 0 00-1.4 1.4v4.2a1.4 1.4 0 001.4 1.4h4.2a1.4 1.4 0 001.4-1.4V49a1.4 1.4 0 00-1.4-1.4z'
          fill='#fff'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56 1.4l-4.005 4.273A4.2 4.2 0 0148.93 7H35.07a4.2 4.2 0 01-3.065-1.327L28 1.4h28z'
          fill='#DFE1F2'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.4 82.6h4.2a4.2 4.2 0 01-4.2-4.2V5.6a4.2 4.2 0 014.2-4.2h-4.2a4.2 4.2 0 00-4.2 4.2v72.8a4.2 4.2 0 004.2 4.2z'
          fill='#fff'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.8 5.6h2.8v11.2h-2.8V5.6z'
          fill='#fff'
        />
        <Path
          d='M18.2 12.6a1.4 1.4 0 100-2.8 1.4 1.4 0 000 2.8z'
          fill='#505798'
        />
        <Path
          d='M22.4 84h39.2a5.6 5.6 0 005.6-5.6V5.6A5.6 5.6 0 0061.6 0H22.4a5.6 5.6 0 00-5.6 5.6 1.4 1.4 0 102.8 0 2.8 2.8 0 012.8-2.8h4.994l3.59 3.83A5.624 5.624 0 0035.07 8.4h13.86a5.615 5.615 0 004.085-1.77l3.591-3.83H61.6a2.8 2.8 0 012.8 2.8v65.8a2.8 2.8 0 01-2.8 2.8H22.4a2.8 2.8 0 01-2.8-2.8V16.8a1.4 1.4 0 10-2.8 0v61.6a5.6 5.6 0 005.6 5.6zM50.971 4.715a2.8 2.8 0 01-2.041.885H35.07a2.808 2.808 0 01-2.043-.885L31.233 2.8h21.534l-1.796 1.915zM19.6 76.222a5.542 5.542 0 002.8.778h39.2a5.543 5.543 0 002.8-.778V78.4a2.8 2.8 0 01-2.8 2.8H22.4a2.8 2.8 0 01-2.8-2.8v-2.178z'
          fill='#505798'
        />
        <Path
          d='M35 28h-4.2a2.8 2.8 0 00-2.8 2.8V35a2.8 2.8 0 002.8 2.8H35a2.8 2.8 0 002.8-2.8v-4.2A2.8 2.8 0 0035 28zm-4.2 7v-4.2H35V35h-4.2zM49 28a2.8 2.8 0 00-2.8 2.8V35a2.8 2.8 0 002.8 2.8h4.2A2.8 2.8 0 0056 35v-4.2a2.8 2.8 0 00-2.8-2.8H49zm0 7v-4.2h4.2V35H49zM28 53.2a2.8 2.8 0 002.8 2.8H35a2.8 2.8 0 002.8-2.8V49a2.8 2.8 0 00-2.8-2.8h-4.2A2.8 2.8 0 0028 49v4.2zm2.8-4.2H35v4.2h-4.2V49zM46.2 53.2A2.8 2.8 0 0049 56h4.2a2.8 2.8 0 002.8-2.8V49a2.8 2.8 0 00-2.8-2.8H49a2.8 2.8 0 00-2.8 2.8v4.2zM49 49h4.2v4.2H49V49z'
          fill='#505798'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.4 29.4a1.4 1.4 0 10-2.8 0v11.2h-2.8a1.4 1.4 0 100 2.8H42a1.4 1.4 0 001.4-1.4V29.4zM32.2 43.4a1.4 1.4 0 100-2.8h-2.8a1.4 1.4 0 100 2.8h2.8zM47.6 43.4h7a1.4 1.4 0 100-2.8h-7a1.4 1.4 0 100 2.8zM42 46.2a1.4 1.4 0 00-1.4 1.4v7a1.4 1.4 0 102.8 0v-7a1.4 1.4 0 00-1.4-1.4zM29.4 22.4h-2.8a4.2 4.2 0 00-4.2 4.2v2.8a1.4 1.4 0 102.8 0v-2.8a1.4 1.4 0 011.4-1.4h2.8a1.4 1.4 0 100-2.8zM22.4 57.4a4.2 4.2 0 004.2 4.2h2.8a1.4 1.4 0 100-2.8h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 10-2.8 0v2.8zM60.2 53.2a1.4 1.4 0 00-1.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 100 2.8h2.8a4.2 4.2 0 004.2-4.2v-2.8a1.4 1.4 0 00-1.4-1.4zM61.6 26.6a4.2 4.2 0 00-4.2-4.2h-2.8a1.4 1.4 0 100 2.8h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 102.8 0v-2.8z'
          fill='#505798'
        />
      </G>
      <Defs>
        <ClipPath id='prefix__clip0'>
          <Path fill='#fff' d='M0 0h84v84H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SvgComponent

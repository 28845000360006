import React, { ReactNode, useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'

import Ionicons from '@expo/vector-icons/Ionicons'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import PressableOpacity from 'components/Buttons/PressableOpacity'

interface SettingsIconTextRowProps {
  children: ReactNode
  iconName: keyof typeof Ionicons.glyphMap
  onPress?: () => void
}

export default function SettingsIconTextRow({
  iconName,
  children,
  onPress,
}: SettingsIconTextRowProps) {
  // This tracks the iconBoxHeight as a layoutEvent so we
  // can make it a circle by applying the relevant borderRadius
  const [iconBoxHeight, setIconBoxHeight] = useState(0)

  return (
    <PressableOpacity style={styles.container} onPress={onPress}>
      <View
        style={[
          styles.iconBox,
          { borderRadius: iconBoxHeight ? iconBoxHeight / 2 : undefined },
        ]}
        onLayout={({ nativeEvent }) =>
          setIconBoxHeight(nativeEvent.layout.height)
        }
      >
        <Ionicons name={iconName} size={25} color={Colors.PRIMARY['500']} />
      </View>
      <Text style={styles.text}>{children}</Text>
    </PressableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: Metrics.mediumSpace,
    paddingVertical: Metrics.space,
    alignItems: 'center',
  },
  iconBox: {
    padding: 5,
    backgroundColor: Colors.PRIMARY[100],
  },
  text: {
    ...Fonts.h5,
    color: Colors.PRIMARY[700],
    marginLeft: Metrics.space,
    flexWrap: 'wrap',
    flexShrink: 1,
  },
})

import React from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import PlainCard from 'components/Cards/PlainCard'

interface TitleValueCardProps {
  title?: string
  value?: string | number
  cardStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export default function TitleValueCard({
  title,
  value = '-',
  cardStyle,
  textStyle,
}: TitleValueCardProps) {
  return (
    <PlainCard style={[styles.headerCardStyle, cardStyle]} isShadow={false}>
      {title && (
        <Text style={styles.headerCardLabel} allowFontScaling={false}>
          {title}
        </Text>
      )}
      <View style={styles.rowFlex}>
        <Text style={[styles.dollarSign, textStyle]} allowFontScaling={false}>
          $
        </Text>
        <Text
          style={[styles.currentValueText, textStyle]}
          allowFontScaling={false}
        >
          {value}
        </Text>
      </View>
    </PlainCard>
  )
}

const styles = StyleSheet.create({
  headerCardStyle: {
    padding: Metrics.mediumSpace,
  },
  headerCardLabel: {
    ...Fonts.body2,
    color: Colors.NEUTRAL[800],
  },
  rowFlex: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  dollarSign: {
    alignSelf: 'center',
    ...Fonts.h5,
    color: Colors.PRIMARY[700],
  },
  currentValueText: {
    ...Fonts.display2,
    color: Colors.PRIMARY[700],
  },
})

import React from 'react'
import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native'

import Fonts from 'theme/Fonts'

import { VERSION_DISPLAY } from 'constants/misc'

interface VersionDisplayProps {
  textColor?: string
  style?: StyleProp<ViewStyle>
}

export default function VersionDisplay({
  style,
  textColor,
}: VersionDisplayProps) {
  return (
    <View style={[styles.absoluteTopRight, style]}>
      <Text
        style={[
          styles.absoluteTopRightText,
          textColor ? { color: textColor } : undefined,
        ]}
      >
        {VERSION_DISPLAY}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  absoluteTopRight: {
    position: 'absolute',
    right: 10,
    top: 0,
    zIndex: 4,
  },
  absoluteTopRightText: {
    ...Fonts.caption,
    color: 'black',
  },
})

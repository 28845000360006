import React from 'react'
import { View, StyleSheet, Text, Pressable } from 'react-native'

import * as Linking from 'expo-linking'
import _ from 'lodash'
import moment from 'moment-timezone'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'
import { Transaction } from 'typings/transaction'

import HeaderIconTextRow, { ICON_TYPE } from 'components/Rows/HeaderIconTextRow'
import { BLOCK_EXPLORER } from 'constants/config'
import { truncateIdentifier } from 'helpers/utils'

const TRANSACTION_TIMESTAMP_FORMAT = 'h:mm A'

interface TransactionCardProps {
  transaction: Transaction
}

// TODO: Consider refactoring items in this card to smaller components
function TransactionCard({ transaction }: TransactionCardProps) {
  const value = _.get(transaction, 'value')
  const timestamp = _.get(transaction, 'createdAt')
  const identifier = _.get(transaction, 'id')
  const name = _.get(transaction, 'verifierName')

  return (
    <Pressable
      style={styles.container}
      onPress={() => Linking.openURL(`${BLOCK_EXPLORER}/tx/${identifier}`)}
    >
      <View style={styles.dataContainer}>
        <Text style={styles.dateText}>
          {moment(timestamp).format(TRANSACTION_TIMESTAMP_FORMAT)}
          {!!name && `, ${name}`}
        </Text>
        <HeaderIconTextRow
          iconType={ICON_TYPE.VOUCHER}
          color={Colors.NEUTRAL[700]}
          style={styles.iconRow}
          textStyle={styles.iconRowText}
          allowFontScaling={false}
        >
          {truncateIdentifier(identifier)}
        </HeaderIconTextRow>
      </View>
      <View style={styles.valueContainer}>
        <Text allowFontScaling={false} style={styles.dollarSign}>
          $
        </Text>
        <Text allowFontScaling={false} style={styles.valueText}>
          {value}
        </Text>
      </View>
    </Pressable>
  )
}

export default React.memo(
  TransactionCard,
  ({ transaction: prevTransaction }, { transaction: nextTransaction }) =>
    prevTransaction &&
    nextTransaction &&
    prevTransaction.id === nextTransaction.id,
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.STANDARD.WHITE,
    padding: Metrics.mediumSpace,
    borderBottomWidth: 1,
    borderBottomColor: Colors.NEUTRAL[200],
    marginHorizontal: Metrics.largeMargin,
  },
  iconRow: {
    marginRight: Metrics.largeMargin,
  },
  iconRowText: {
    ...Fonts.legal,
    paddingTop: Metrics.smallSpace,
    overflow: 'hidden',
  },
  dataContainer: {
    flexShrink: 1,
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dollarSign: {
    ...Fonts.caption2,
    color: Colors.PRIMARY[500],
  },
  valueText: {
    ...Fonts.h4,
    color: Colors.PRIMARY[500],
  },
  dateText: {
    ...Fonts.subhead2,
    color: Colors.NEUTRAL[800],
  },
})

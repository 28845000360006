// Returns a TextInput box with an icon depending on the provided icon name
import React, { useRef, useState } from 'react'
import {
  TextInput,
  Text,
  View,
  StyleSheet,
  TextInputProps,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform,
} from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import _ from 'lodash'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import BlinkingCursor from 'components/BlinkingCursor'
import ErrorIconTextRow from 'components/Rows/ErrorIconTextRow'

export interface CustomTextInputProps extends TextInputProps {
  value: string
  invalidInputText: string
  fixedPlaceholder?: string
  iconName?: keyof typeof Ionicons.glyphMap
  isOtp?: boolean
  iconStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  onSubmitEditing: () => void
}

export default function CustomTextInput({
  iconName,
  // Style props
  style,
  isOtp,
  iconStyle,
  textStyle,
  // TextInput props
  keyboardType = 'number-pad',
  clearButtonMode,
  fixedPlaceholder,
  placeholderTextColor = Colors.NEUTRAL[500],
  placeholder,
  defaultValue,
  editable = true,
  value,
  autoCapitalize,
  onFocus,
  selectTextOnFocus,
  autoFocus,
  maxLength,
  // RN sets autoCorrect to true by default, but we typically do not want to
  // autocorrect our inputs.
  autoCorrect = false,
  returnKeyType = 'done',
  // Handlers
  onBlur,
  onSubmitEditing,
  onChangeText,
  invalidInputText,
}: CustomTextInputProps) {
  const [hasFocus, setHasFocus] = useState(false)
  const innerRef = useRef(null)
  const codeDigitsArray = new Array(maxLength).fill(0)

  return (
    <View>
      <View
        style={[
          styles.textInputBox,
          hasFocus
            ? { borderColor: Colors.PRIMARY[500], borderWidth: 2 }
            : undefined,
          invalidInputText
            ? { borderColor: Colors.DANGER[500], borderWidth: 2 }
            : undefined,
          !editable ? styles.disabledTextInputBox : undefined,
          isOtp ? styles.otpBox : undefined,
          style,
        ]}
      >
        {isOtp &&
          _.map(codeDigitsArray, (_number, idx) => {
            const rightBorder =
              codeDigitsArray.length !== idx + 1
                ? {
                    borderRightWidth: 1,
                    borderColor: Colors.NEUTRAL[400],
                  }
                : undefined

            return (
              <View style={[styles.otpDigitBox, rightBorder]} key={idx}>
                <Text style={styles.otpDigitText}>
                  {value[idx]}
                  <Text style={{ color: Colors.NEUTRAL[500] }}>
                    {value.length === idx && hasFocus && <BlinkingCursor />}
                  </Text>
                </Text>
              </View>
            )
          })}
        {iconName && (
          <Ionicons name={iconName} style={[styles.icon, iconStyle]} />
        )}
        {fixedPlaceholder && (
          <Text
            style={[
              styles.fixedPlaceholderStyle,
              { color: placeholderTextColor },
            ]}
          >
            {fixedPlaceholder}
          </Text>
        )}
        <TextInput
          ref={innerRef}
          keyboardType={keyboardType}
          style={[
            styles.textStyle,
            textStyle,
            isOtp ? styles.hiddenCodeInput : undefined,
          ]}
          underlineColorAndroid="transparent"
          clearButtonMode={clearButtonMode}
          placeholderTextColor={placeholderTextColor}
          placeholder={placeholder}
          defaultValue={defaultValue}
          editable={editable}
          value={value}
          autoCapitalize={autoCapitalize}
          onFocus={(e) => {
            setHasFocus(true)
            if (onFocus) {
              onFocus(e)
            }
          }}
          selectTextOnFocus={selectTextOnFocus}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          maxLength={maxLength}
          returnKeyType={returnKeyType}
          onBlur={(e) => {
            setHasFocus(false)
            if (onBlur) {
              onBlur(e)
            }
          }}
          onSubmitEditing={onSubmitEditing}
          onChangeText={onChangeText}
        />
      </View>
      {!!invalidInputText && (
        <ErrorIconTextRow style={styles.errorIconTextRow}>
          {invalidInputText}
        </ErrorIconTextRow>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  textInputBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Metrics.space,
    paddingHorizontal: Metrics.smallSpace,
    borderWidth: 1,
    borderColor: Colors.NEUTRAL[400],
    borderRadius: Metrics.borderRadius,
  },
  otpBox: {
    height: 54,
    alignItems: 'stretch',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  otpDigitBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  otpDigitText: {
    ...Fonts.h3,
  },
  fixedPlaceholderStyle: {
    ...Fonts.body1,
    paddingHorizontal: Metrics.smallSpace,
    top: Platform.OS === 'ios' ? 2.5 : 0, // iOS requires offset to center it with the textinput
  },
  textStyle: {
    ...Fonts.body1,
    flex: 1,
    paddingHorizontal: Metrics.smallSpace,
    // Magic numbers to center text in iOS
    paddingVertical: Platform.OS === 'ios' ? 5 : 0,
    lineHeight: Platform.OS === 'ios' ? 19 : Fonts.body1.lineHeight,
  },
  icon: {
    fontSize: Fonts.body1.fontSize,
    color: Colors.PRIMARY[500],
    paddingHorizontal: Metrics.smallSpace,
  },
  disabledTextInputBox: {
    padding: 0,
    backgroundColor: Colors.NEUTRAL[200],
  },
  hiddenCodeInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  errorIconTextRow: {
    marginTop: Metrics.space,
  },
})

import React, { FunctionComponent, ReactNode } from 'react'
import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

interface ImageTextViewProps {
  ImageComponent: FunctionComponent<{ style: StyleProp<ViewStyle> }>
  children: ReactNode
  secondaryText?: string
  style?: StyleProp<ViewStyle>
}

export default function ImageTextView({
  ImageComponent,
  children,
  secondaryText,
  style,
}: ImageTextViewProps) {
  return (
    <View style={[styles.container, style]}>
      <ImageComponent style={styles.imageStyle} />
      <Text style={styles.primaryText}>{children}</Text>
      {!!secondaryText && (
        <Text style={styles.secondaryText}>{secondaryText}</Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: Metrics.hugeSpace,
  },
  imageStyle: {
    marginBottom: Metrics.largeSpace,
  },
  primaryText: {
    ...Fonts.subhead1,
    color: Colors.PRIMARY[500],
    textAlign: 'center',
  },
  secondaryText: {
    ...Fonts.body2,
    textAlign: 'center',
    marginTop: Metrics.space,
  },
})

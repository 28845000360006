// For handling unused gestures (future)
import 'react-native-gesture-handler'
import 'react-native-get-random-values'

// Pulled shims for better cryptography functions  - currently unused
import '@ethersproject/shims'

import React from 'react'

import { SafeAreaProvider } from 'react-native-safe-area-context'
import Toast from 'react-native-toast-message'
import { WagmiConfig } from 'wagmi'

import { AuthProvider } from 'context/AuthContext'
import { useCachedResources } from 'hooks/useCachedResources'
import { initWebMonitoring } from 'lib/datadog'
import { toastConfig } from 'lib/toasts'
import { globalClient } from 'lib/wagmi'
import NavigationRouter from 'router/NavigationRouter'
import LoadingScreen from 'screens/LoadingScreen'

initWebMonitoring()

export default function App() {
  const isLoadingComplete = useCachedResources()

  // TODO: Insert splash screen here for mobile, use async load w/ fallbacks for web
  if (!isLoadingComplete) {
    return <LoadingScreen />
  }

  return (
    <WagmiConfig client={globalClient}>
      <AuthProvider>
        <SafeAreaProvider>
          <NavigationRouter />
        </SafeAreaProvider>
      </AuthProvider>
      <Toast config={toastConfig} />
    </WagmiConfig>
  )
}

import { MaterialIcons } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import _ from 'lodash'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import { MainTabParamList } from 'router/types'
import PayoutsScreen from 'screens/payouts/PayoutsScreen'
import SettingsScreen from 'screens/SettingsScreen'
import TransactionsScreen from 'screens/transactions/TransactionScreen'

const Tab = createBottomTabNavigator<MainTabParamList>()

export default function NavigationTabs() {
  return (
    <Tab.Navigator
      initialRouteName="Transactions"
      backBehavior="none"
      screenOptions={() => ({
        headerShown: false,
        tabBarAllowFontScaling: false,
        tabBarActiveTintColor: Colors.STANDARD.WHITE,
        tabBarActiveBackgroundColor: Colors.PRIMARY[800],
        // Cannot specify color or the label won't inherit activeTintColor
        tabBarLabelStyle: _.omit(Fonts.caption2, 'color'),
        tabBarItemStyle: {
          borderRadius: 8,
          paddingVertical: Metrics.smallSpace,
          marginHorizontal: Metrics.space,
        },
        tabBarStyle: [
          {
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 5,
            },
            shadowOpacity: 0.34,
            shadowRadius: 6.27,
            elevation: 10,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            paddingTop: 12,
            paddingBottom: 12,
            height: 87,
          },
          null,
        ],
      })}
    >
      <Tab.Screen
        name="Transactions"
        component={TransactionsScreen}
        options={{
          tabBarLabel: 'Transactions',
          tabBarIcon: ({ color, size }) => (
            <MaterialIcons name="receipt" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Payouts"
        component={PayoutsScreen}
        options={{
          tabBarLabel: 'Payouts',
          tabBarIcon: ({ color, size }) => (
            <MaterialIcons name="attach-money" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          tabBarLabel: 'Settings',
          tabBarIcon: ({ color, size }) => (
            <MaterialIcons name="settings" size={size} color={color} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

import React, { useState } from 'react'
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  StyleProp,
  ViewStyle,
} from 'react-native'

import { Ionicons } from '@expo/vector-icons'

import Colors from 'theme//Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

interface InformationDrawerProps {
  content: string
  prompt: string
  style?: StyleProp<ViewStyle>
}

export default function InformationDrawer({
  style,
  content,
  prompt,
}: InformationDrawerProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <View style={style}>
      <Pressable
        style={styles.pressable}
        onPress={() => setIsExpanded((prevState) => !prevState)}
      >
        <Ionicons
          style={styles.helpCircle}
          name="help-circle"
          size={Metrics.icons.small}
        />
        <Text style={styles.promptText}>{prompt}</Text>
        {/* Show up arrow when isExpanded, and down arrow when !isExpanded */}
        {isExpanded ? (
          <Ionicons
            style={styles.chevron}
            name="chevron-up"
            size={Metrics.icons.small}
          />
        ) : (
          <Ionicons
            style={styles.chevron}
            name="chevron-down"
            size={Metrics.icons.small}
          />
        )}
      </Pressable>
      {/* Display drawer content if isExpanded */}
      {isExpanded && (
        <View style={styles.contentContainer}>
          <Text style={styles.contentText}>{content}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    backgroundColor: Colors.PRIMARY[900],
    paddingVertical: Metrics.mediumSpace,
    paddingHorizontal: Metrics.largeSpace,
    marginTop: Metrics.baseMargin,
  },
  contentText: {
    ...Fonts.body2,
    color: Colors.STANDARD.WHITE,
  },
  promptText: {
    ...Fonts.subhead2,
    color: Colors.STANDARD.WHITE,
    flexWrap: 'wrap',
  },
  chevron: {
    color: Colors.STANDARD.WHITE,
    marginLeft: Metrics.baseMargin,
  },
  helpCircle: {
    color: Colors.STANDARD.WHITE,
    marginRight: Metrics.smallMargin,
  },
})

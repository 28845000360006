import { chain, etherscanBlockExplorers } from 'wagmi'

// TODO: Remove in place of dynamic queries
export const PBM_DECIMALS = 18

export const BLOCK_EXPLORER =
  process.env.APP_ENV === 'production'
    ? etherscanBlockExplorers.polygonMumbai.url
    : etherscanBlockExplorers.polygonMumbai.url

// Config variables for contract addresses
export const DSGD_ADDRESS = process.env.DSGD_ADDRESS || ''
export const PBM_ADDRESS = process.env.PBM_ADDRESS || ''

// address should only be provided if transfer events require additional filtering
export const PAYOUT_INITIATOR_ADDRESS =
  process.env.PAYOUT_INITIATOR_ADDRESS || undefined

// App related configurations
export const BASE_URL =
  process.env.APP_ENV === 'production'
    ? 'https://merchant-experimental.dsgd.gov.sg'
    : 'https://merchant-staging.dsgd.gov.sg'

const productionBaseUrl = 'https://api-experimental.dsgd.gov.sg'
const stagingBaseUrl = 'https://api-staging.dsgd.gov.sg'
const localBaseUrl = 'http://localhost:10000'

export const API_BASE_URL = (() => {
  if (process.env.APP_ENV === 'production') {
    return productionBaseUrl
  }
  if (process.env.APP_ENV === 'staging') {
    return stagingBaseUrl
  }

  return localBaseUrl
})()

export const CHAIN =
  process.env.APP_ENV === 'production'
    ? chain.polygonMumbai
    : chain.polygonMumbai

export const INFURA_API_KEY = process.env.INFURA_API_KEY || ''
export const ALCHEMY_API_KEY = process.env.ALCHEMY_API_KEY || ''

import React, { useState } from 'react'
import { StyleSheet, Linking } from 'react-native'

import Metrics from 'theme/Metrics'

import ShopImage from 'assets/svg/shop'
import BaseRegistrationScreen from 'components/Views/BaseRegistrationView'
import { getChecksumedAddress } from 'helpers/utils'
import useAddressCheck from 'hooks/useAddressCheck'
import useAuth from 'hooks/useAuth'
import { EnterCodeScreenProps } from 'router/types'

const headerText = 'Enter wallet address'
const placeholder = '0x34d34c...'
const instruction = 'Enter the wallet code given to your shop'
const primaryButtonText = 'Join shop'
const topLeftButtonText = 'Logout'

// TODO: Show expiry in success added to shop modal
export default function EnterCodeScreen({ route }: EnterCodeScreenProps) {
  const [textInput, setTextInput] = useState(route.params?.id || '')
  const [invalidInputText, setInvalidInputText] = useState('')

  const { signIn } = useAuth()
  const { mutate, isLoading } = useAddressCheck()

  const primaryAction = async () => {
    // Tries to format address into a valid checksum
    // TODO: Move validation into useAddressCheck
    try {
      const addressInput = getChecksumedAddress(textInput)
      // Verify that address is correct format
      mutate(addressInput, {
        onSuccess: () => signIn(addressInput),
        onError: () =>
          setInvalidInputText('This address is not valid for this campaign'),
      })
    } catch (e) {
      setInvalidInputText('This address is not valid.')
    }
  }

  return (
    <BaseRegistrationScreen
      allowHeaderFontScaling={false}
      headerText={headerText}
      IllustrationImage={ShopImage}
      placeholder={placeholder}
      instruction={instruction}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonPress={primaryAction}
      isPrimaryLoading={isLoading}
      keyboardType="default"
      textInput={textInput}
      autoFocus
      autoCapitalize="none"
      setTextInput={(text: string) => {
        setTextInput(text)
        // On edit, clear the invalid input text if it was set
        if (invalidInputText) {
          setInvalidInputText('')
        }
      }}
      invalidInputText={invalidInputText}
      inputMaxLength={42}
    />
  )
}

const styles = StyleSheet.create({
  noShopCodeButton: {
    alignSelf: 'center',
    marginTop: Metrics.space,
  },
})

import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import _ from 'lodash'
import moment from 'moment-timezone'
import Toast from 'react-native-toast-message'
import { useBalance } from 'wagmi'

import Colors from 'theme/Colors'
import Metrics from 'theme/Metrics'
import { TransferEventWithTimestamp } from 'typings/transaction'

import BankImage from 'assets/svg/bank'
import InformationDrawer from 'components/Drawer/InformationDrawer'
import HeaderBlock from 'components/HeaderBlock'
import TransparentGradient from 'components/Misc/TransparentGradient'
import ToggleButtonsRow from 'components/Rows/ToggleButtonsRow'
import ImageTextView from 'components/Views/ImageTextView'
import { DSGD_ADDRESS, PAYOUT_INITIATOR_ADDRESS } from 'constants/config'
import { formatBigNumberToDisplay } from 'helpers/utils'
import useAuth from 'hooks/useAuth'
import useMerchantDetails from 'hooks/useMerchantDetails'
import useTransferEventViaApi from 'hooks/useTransferEventViaApi'
import ScreenTemplate from 'templates/ScreenTemplate'
import dsgd from 'web3/abi/dsgd.json'

import PayoutCard from './components/PayoutCard'
import PayoutHeaderCard from './components/PayoutHeaderCard'
import { getMerchantName } from 'constants/hardcode'

const HEADER_TEXT = 'Payouts'
const LEFT_BUTTON_TEXT = 'DAILY'
const NO_PAYOUTS_TEXT = "Bank payments to the shop's account will appear here"
const LOADING_PAYOUTS_TEXT = 'Retrieving your payouts...'

const INFO_DRAWER_PROMPT = 'When will I get my money?'
const INFO_DRAWER_TEXT =
  'At the end of each working day, we will transfer the money for vouchers scanned that day to your registered bank account. This should arrive within a day.\n\nIf monies do not appear after 2 days, you may contact us on the provided Whatsapp channel.'

const DAY_FORMAT = 'D MMMM YYYY'

// TODO: Look at error state for unable to fetch payout
export default function PayoutsScreen() {
  const { merchantAddress } = useAuth()

  const { data: merchantData } = useMerchantDetails({
    address: merchantAddress,
  })

  // Total value to be transferred refers to the current merchant's DSGD Balance
  // In the CDBC Trial, a payout settlement refers to the movement of DSGD from merchant -> approved bank wallet
  const { data: dsgdBalance } = useBalance({
    token: DSGD_ADDRESS,
    addressOrName: merchantAddress,
    watch: true,
    onError: (e) => {
      Toast.show({
        type: 'error',
        text1: 'Failed to connect to provider',
        text2: `reason: ${e.name}`,
      })
    },
  })

  // Listens in on Transfer events happening from Merchant -> Bank. This symbolises a payout
  const { data: payouts, isLoading } = useTransferEventViaApi({
    key: 'payoutEvent',
    toAddress: PAYOUT_INITIATOR_ADDRESS,
    fromAddress: merchantAddress,
    abi: dsgd.abi,
    contractAddress: DSGD_ADDRESS,
    startBlock: 7460420,
  })

  const header = (
    <View style={styles.headerView}>
      <HeaderBlock
        headerText={HEADER_TEXT}
        merchantName={getMerchantName(merchantAddress, merchantData?.shopName)}
        innerElement={
          <>
            <PayoutHeaderCard
              value={dsgdBalance && formatBigNumberToDisplay(dsgdBalance.value)}
            />
            <InformationDrawer
              prompt={INFO_DRAWER_PROMPT}
              content={INFO_DRAWER_TEXT}
            />
            <ToggleButtonsRow
              activeButton="active_button_left"
              leftButtonText={LEFT_BUTTON_TEXT}
              onLeftButtonPress={() => null}
            />
          </>
        }
      />
    </View>
  )

  const renderItem = ({ item }: { item: TransferEventWithTimestamp }) => {
    const { transactionHash, timestamp, txValue } = item
    const displayDate = moment(timestamp).format(DAY_FORMAT)

    return (
      <PayoutCard
        id={transactionHash}
        date={displayDate}
        value={formatBigNumberToDisplay(txValue)}
        // TODO: Consider if another state is needed
        status="transferred"
      />
    )
  }

  return (
    <ScreenTemplate>
      {
        // When no payouts, show illustration for no payouts, else show payouts list
        _.isEmpty(payouts) ? (
          <>
            {header}
            <ImageTextView ImageComponent={BankImage}>
              {isLoading ? LOADING_PAYOUTS_TEXT : NO_PAYOUTS_TEXT}
            </ImageTextView>
          </>
        ) : (
          <View style={styles.containingView}>
            <FlatList
              style={styles.listContainer}
              contentContainerStyle={styles.listContentContainer}
              data={payouts}
              renderItem={renderItem}
              ListHeaderComponent={header}
              ItemSeparatorComponent={() => <View style={styles.separator} />}
              keyExtractor={(item) => item.transactionHash}
            />
            <TransparentGradient
              height={Metrics.gradientHeight}
              horizontalPadding={Metrics.largeSpace}
            />
          </View>
        )
      }
    </ScreenTemplate>
  )
}

const styles = StyleSheet.create({
  safeAreaView: {
    backgroundColor: Colors.PRIMARY[100],
    flex: 1,
  },
  headerView: {
    marginBottom: Metrics.largeMargin,
  },
  containingView: {
    flex: 1,
  },
  listContainer: {
    marginBottom: Metrics.baseMargin,
  },
  listContentContainer: {
    paddingBottom: Metrics.hugeSpace,
  },
  separator: {
    borderBottomColor: Colors.NEUTRAL[300],
    borderBottomWidth: 1,
    marginVertical: Metrics.largeMargin,
  },
})

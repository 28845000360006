import React from 'react'
import { View, StyleSheet, Text, Pressable } from 'react-native'

import * as Linking from 'expo-linking'

import Metrics from 'theme//Metrics'
import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'

import { BLOCK_EXPLORER } from 'constants/config'
import { truncateIdentifier } from 'helpers/utils'
import { PayoutStatus } from 'screens/payouts/types'

import PayoutStatusBadge from './PayoutStatusBadge'

interface PayoutCardProps {
  id: string
  date: string
  value: string
  status: PayoutStatus
}

function PayoutCard({ id, date, value, status }: PayoutCardProps) {
  return (
    <Pressable
      style={styles.rowView}
      onPress={() => Linking.openURL(`${BLOCK_EXPLORER}/tx/${id}`)}
    >
      <View style={styles.dataContainer}>
        <PayoutStatusBadge status={status} style={styles.statusBadge} />
        <Text style={styles.dateText}>{date}</Text>
        <Text numberOfLines={1} style={styles.detailsText}>
          {truncateIdentifier(id)}
        </Text>
      </View>
      <View style={styles.valueView}>
        <Text style={styles.dollarSign}>$</Text>
        <Text style={styles.valueText}>{value}</Text>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  rowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: Metrics.largeMargin,
  },
  dataContainer: {
    flexShrink: 1,
    alignItems: 'flex-start',
  },
  dateText: {
    ...Fonts.subhead1,
    flexWrap: 'wrap',
  },
  valueView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dollarSign: Fonts.body2,
  valueText: Fonts.h3,
  detailsText: {
    ...Fonts.caption2,
    overflow: 'hidden',
    color: Colors.NEUTRAL[700],
  },
  statusBadge: {
    marginBottom: Metrics.baseMargin,
  },
})

export default React.memo(
  PayoutCard,
  (
    { id: prevPayoutId, status: prevPayoutStatus },
    { id: nextPayoutId, status: nextPayoutStatus },
  ) => prevPayoutId === nextPayoutId && prevPayoutStatus === nextPayoutStatus,
)

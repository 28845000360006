// Returns a view with transparent gradient
// This is used to indicate there's more scrollable content

import React from 'react'
import { StyleSheet } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'
import _ from 'lodash'

import Colors from 'theme/Colors'

interface TransparentGradientProps {
  height?: number
  inversePosition?: boolean
  flip?: boolean
  horizontalPadding?: number
  gradientColor?: string
  opacity?: number
  offset?: number
}

export default function TransparentGradient({
  height,
  inversePosition,
  flip,
  horizontalPadding = 0,
  gradientColor,
  opacity,
  offset = 0,
}: TransparentGradientProps) {
  const resultingHeight = { height: height || 50 }
  const linearGradientStyle = [
    {
      ...styles.linearGradient,
      left: horizontalPadding,
      right: horizontalPadding,
    },
    inversePosition
      ? { top: -resultingHeight.height + offset }
      : { bottom: 0 + offset },
  ]

  const resultingOpacity = opacity || 1 // Default linear target opacity
  const alpha = Math.floor(resultingOpacity * 255).toString(16)

  // Use gradientColor if provided, else default to primary.
  // 00 indicates alpha channel of 0, which is transparent
  const resultingGradientColor = gradientColor
    ? [`${gradientColor}00`, `${gradientColor}${alpha}`]
    : [`${Colors.PRIMARY[100]}00`, `${Colors.PRIMARY[100]}${alpha}`]

  return (
    <LinearGradient
      pointerEvents="none"
      style={[linearGradientStyle, resultingHeight]}
      colors={flip ? _.reverse(resultingGradientColor) : resultingGradientColor}
    />
  )
}

const styles = StyleSheet.create({
  linearGradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 9,
  },
})

import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function SvgComponent(props) {
  return (
    <Svg
      width={84}
      height={84}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path d='M25.2 46.2H2.8v36.4h22.4V46.2z' fill='#fff' />
      <Path d='M58.795 28h-33.6v54.6h33.6V28z' fill='#fff' />
      <Path d='M81.195 46.2h-22.4v36.4h22.4V46.2z' fill='#fff' />
      <Path d='M81.195 46.2h-4.2v36.4h4.2V46.2z' fill='#DFE1F2' />
      <Path d='M7 46.2H2.8v36.4H7V46.2z' fill='#fff' />
      <Path
        d='M33.595 35h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM47.596 35h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM33.596 46.2h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM47.596 46.2h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM33.596 57.4h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM47.596 57.4h2.8a1.4 1.4 0 011.4 1.4v2.8a1.4 1.4 0 01-1.4 1.4h-2.8a1.4 1.4 0 01-1.4-1.4v-2.8a1.4 1.4 0 011.4-1.4zM18.2 51.8H9.8v8.4h8.4v-8.4z'
        fill='#DFE1F2'
      />
      <Path
        d='M58.8 84H25.2a1.4 1.4 0 01-1.4-1.4V28a1.4 1.4 0 011.4-1.4h33.6a1.4 1.4 0 011.4 1.4v54.6a1.4 1.4 0 01-1.4 1.4zm-32.2-2.8h30.8V29.4H26.6v51.8z'
        fill='#505798'
      />
      <Path
        d='M81.2 84H58.8a1.4 1.4 0 01-1.4-1.4V46.2a1.4 1.4 0 011.4-1.4h22.4a1.4 1.4 0 011.4 1.4v36.4a1.4 1.4 0 01-1.4 1.4zm-21-2.8h19.6V47.6H60.2v33.6zM25.2 84H2.8a1.4 1.4 0 01-1.4-1.4V46.2a1.4 1.4 0 011.4-1.4h22.4a1.4 1.4 0 011.4 1.4v36.4a1.4 1.4 0 01-1.4 1.4zm-21-2.8h19.6V47.6H4.2v33.6z'
        fill='#505798'
      />
      <Path
        d='M2.8 47.6H1.4a1.4 1.4 0 110-2.8h1.4a1.4 1.4 0 010 2.8zM82.6 47.6h-1.4a1.4 1.4 0 110-2.8h1.4a1.4 1.4 0 110 2.8zM61.6 29.4H22.4a1.4 1.4 0 110-2.8h39.2a1.4 1.4 0 110 2.8zM36.4 42h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM50.4 42h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM36.4 53.2h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM50.4 53.2h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM36.4 64.4h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM50.4 64.4h-2.8a2.803 2.803 0 01-2.8-2.8v-2.8a2.803 2.803 0 012.8-2.8h2.8a2.803 2.803 0 012.8 2.8v2.8a2.803 2.803 0 01-2.8 2.8zm-2.8-5.6v2.8h2.802l-.002-2.8h-2.8zM47.6 84H36.4a1.4 1.4 0 01-1.4-1.4V70a1.4 1.4 0 011.4-1.4h11.2A1.4 1.4 0 0149 70v12.6a1.4 1.4 0 01-1.4 1.4zm-9.8-2.8h8.4v-9.8h-8.4v9.8zM42 23.8a5.606 5.606 0 01-5.6-5.6 1.4 1.4 0 012.8 0 2.8 2.8 0 102.8-2.8 5.6 5.6 0 115.6-5.6 1.4 1.4 0 11-2.8 0 2.8 2.8 0 10-2.8 2.8 5.6 5.6 0 010 11.2z'
        fill='#505798'
      />
      <Path
        d='M42 29.4a1.4 1.4 0 01-1.4-1.4V1.4a1.4 1.4 0 012.8 0V28a1.4 1.4 0 01-1.4 1.4zM18.2 78.4H9.8A1.4 1.4 0 018.4 77V65.8a1.4 1.4 0 011.4-1.4h8.4a1.4 1.4 0 011.4 1.4V77a1.4 1.4 0 01-1.4 1.4zm-7-2.8h5.6v-8.4h-5.6v8.4zM18.2 61.6H9.8a1.4 1.4 0 01-1.4-1.4v-8.4a1.4 1.4 0 011.4-1.4h8.4a1.4 1.4 0 011.4 1.4v8.4a1.4 1.4 0 01-1.4 1.4zm-7-2.8h5.6v-5.6h-5.6v5.6zM74.2 78.4h-8.4a1.4 1.4 0 01-1.4-1.4V65.8a1.4 1.4 0 011.4-1.4h8.4a1.4 1.4 0 011.4 1.4V77a1.4 1.4 0 01-1.4 1.4zm-7-2.8h5.6v-8.4h-5.6v8.4zM74.2 61.6h-8.4a1.4 1.4 0 01-1.4-1.4v-8.4a1.4 1.4 0 011.4-1.4h8.4a1.4 1.4 0 011.4 1.4v8.4a1.4 1.4 0 01-1.4 1.4zm-7-2.8h5.6v-5.6h-5.6v5.6z'
        fill='#505798'
      />
    </Svg>
  )
}

export default SvgComponent

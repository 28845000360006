import { LinkingOptions } from '@react-navigation/native'
import { createURL } from 'expo-linking'

import { RootParamList } from 'router/types'

const prefix = createURL('/')

export const linking: LinkingOptions<RootParamList> = {
  prefixes: [prefix],
  config: {
    initialRouteName: 'Main',
    screens: {
      Main: {},
      // Param id is marked as optional. If not specfied, will require manual input
      Login: {
        path: 'login/:id?',
      },
    },
  },
}

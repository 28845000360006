import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useQueryClient } from 'wagmi'

import { getChecksumedAddress, isAddress } from 'helpers/utils'
import { setDatadogUser } from 'lib/datadog'
import { deleteAddress, getAddress, setAddress } from 'services/LoginService'

interface AuthContextProps {
  isSignedIn: boolean
  merchantAddress: string
  signIn: (address: string) => Promise<void>
  signOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

// Note: Consider moving providers to a separate folder as it grows larger
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)
  const queryClient = useQueryClient()

  /*
    TODO: Remove merchant address state hook
    Ideally, anything that requires the merchant address should retrieve it via the
    storage function `getAddress` instead
    This state was added due to laziness in evaluating how storage behaves cross-platform
   */
  const [merchantAddress, setMerchantAddress] = useState<string>('')

  const initializeUser = useCallback((address: string) => {
    setMerchantAddress(address)
    setIsSignedIn(!!address)
    setDatadogUser({ id: address })
  }, [])

  // IIFE to check signin state on first render
  useEffect(() => {
    const bootstrap = async () => {
      // TODO: This should be moved to backend calls, in this bootstrap function we should fetch user info and not token
      const address = await getAddress()
      if (address) {
        console.log('bootstrapped address', address)
        initializeUser(address)
      }
    }

    void bootstrap()
  }, [])

  const signIn = useCallback(async (address: string) => {
    // TODO: move proper address verification elsewhere
    if (isAddress(address)) {
      await setAddress(address)
      initializeUser(address)
    }
  }, [])

  const signOut = useCallback(async () => {
    await deleteAddress()
    queryClient.clear()
    setIsSignedIn(false)
  }, [])

  const value = useMemo(
    () => ({ isSignedIn, signIn, signOut, merchantAddress }),
    [isSignedIn, signIn, signOut, merchantAddress],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('AuthContext must be used within a AuthContextProvider')
  }

  return context
}

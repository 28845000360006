import React from 'react'

import { NavigationContainer } from '@react-navigation/native'
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack'

import useAuth from 'hooks/useAuth'
import { linking } from 'router/linking'
import NavigationMainStack from 'router/NavigationMainStack'
import EnterCodeScreen from 'screens/EnterCodeScreen'

const { Navigator, Screen, Group } = createStackNavigator()

export default function NavigationRouter() {
  const { isSignedIn } = useAuth()

  return (
    <NavigationContainer linking={linking}>
      <Navigator
        initialRouteName="Main"
        screenOptions={() => ({
          ...TransitionPresets.ModalFadeTransition,
          ...{ gestureEnabled: false },
          headerShown: false,
        })}
      >
        {!isSignedIn ? (
          <Group key="unauthorized">
            <Screen name="Login" component={EnterCodeScreen} />
          </Group>
        ) : (
          <Group key="authorized">
            <Screen name="Main" component={NavigationMainStack} />
          </Group>
        )}
      </Navigator>
    </NavigationContainer>
  )
}

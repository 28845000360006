import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'

export default function LoadingScreen() {
  return (
    <View style={styles.container}>
      <Text style={styles.loadingText}>Loading</Text>
      <ActivityIndicator
        style={styles.loader}
        size="large"
        color={Colors.PRIMARY['700']}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  loadingText: {
    ...Fonts.h3,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    color: Colors.PRIMARY['500'],
  },
  loader: {
    marginTop: '10px',
  },
})

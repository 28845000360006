import React, { ReactNode, useEffect } from 'react'
import {
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

export interface PrimaryButtonProps {
  isLoading: boolean
  onPress: () => void
  children: ReactNode
  autoTriggerDurationInMs?: number
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  disabledStyle?: StyleProp<ViewStyle>
  disabledTextStyle?: StyleProp<TextStyle>
}

export default function PrimaryButton({
  style,
  textStyle = {},
  isLoading,
  autoTriggerDurationInMs,
  disabled = false,
  disabledStyle,
  disabledTextStyle,
  onPress,
  children,
}: PrimaryButtonProps) {
  // When loading, also set disabled as true
  if (isLoading) {
    // eslint-disable-next-line no-param-reassign
    disabled = true
  }

  const combinedTextStyle = [
    styles.text,
    textStyle,
    disabled && styles.disabledTextStyle,
    disabled && disabledTextStyle,
  ]

  // On mount, setTimeout to auto trigger the press handler if
  // autoTriggerDurationInMs is provided
  useEffect(() => {
    const autoTrigger = autoTriggerDurationInMs
      ? setTimeout(onPress, autoTriggerDurationInMs)
      : null
    return () => {
      autoTrigger && clearTimeout(autoTrigger)
    }
  }, [autoTriggerDurationInMs])

  return (
    <TouchableOpacity
      style={[
        styles.primaryButton,
        style,
        disabled && styles.disabled,
        disabled && disabledStyle,
      ]}
      disabled={disabled}
      onPress={onPress}
    >
      {/* When loading show a spinner */}
      {/* Spinners dont spin when inside text elements */}
      {/* And android doesnt allow views inside texts */}
      {isLoading ? (
        <>
          <ActivityIndicator
            size="small"
            color={styles.disabledTextStyle.color}
          />
          {/* This empty space text here prevents the button from shrinking when the
          ActivityIndicator shows up */}
          {/* eslint-disable-next-line react/self-closing-comp */}
          <Text style={combinedTextStyle}> </Text>
        </>
      ) : (
        <Text style={combinedTextStyle}>{children}</Text>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  primaryButton: {
    padding: Metrics.space + 2,
    borderRadius: Metrics.borderRadius,
    backgroundColor: Colors.PRIMARY[500],
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  disabled: {
    backgroundColor: Colors.PRIMARY[200],
  },
  disabledTextStyle: {
    color: Colors.STANDARD.WHITE,
  },
  text: {
    ...Fonts.subhead1,
    textAlign: 'center',
    zIndex: 1, // This prevents the AnimatedGradient covering the text
    color: Colors.STANDARD.WHITE,
  },
})

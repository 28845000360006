import { Merchant } from 'typings/merchant'
import { TransactionResponse } from 'typings/transaction'

import baseConfig from './baseConfig'

interface FetchMerchantEventsParams {
  fromAddress?: string
  toAddress?: string
}

export function fetchMerchantByAddress(address: string): Promise<Merchant> {
  // console.log(`GET fetchGroupById /vouchers/groups/${id}`)
  return baseConfig
    .get(`/public/merchant?wallet_address=${address}`)
    .then((res) => res.data.data)
}

export function fetchMerchantDsgdTransfers({
  fromAddress,
  toAddress,
}: FetchMerchantEventsParams): Promise<TransactionResponse> {
  return baseConfig
    .get('/token/dsgd/transfers', {
      params: {
        from: fromAddress,
        to: toAddress,
      },
    })
    .then((res) => res.data)
}

import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'

import PressableOpacity, {
  PressableOpacityProps,
} from 'components/Buttons/PressableOpacity'

interface TextButtonProps extends PressableOpacityProps {
  isLoading?: boolean
  textStyle?: StyleProp<TextStyle>
  children: ReactNode
}

export default function TextButton({
  onPress,
  style,
  textStyle,
  children,
  disabled,
  isLoading = false,
  hitSlop = 5,
}: TextButtonProps) {
  return (
    <PressableOpacity
      onPress={onPress}
      style={[styles.buttonStyle, style]}
      disabled={disabled || isLoading}
      hitSlop={hitSlop}
    >
      <Text style={[styles.textStyle, textStyle]}>
        {isLoading ? 'Loading' : children}
      </Text>
    </PressableOpacity>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    ...Fonts.subhead1,
    color: Colors.PRIMARY[500],
    textAlign: 'center',
  },
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
})

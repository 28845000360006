import { ReactNode } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

// TODO: Consider wrapping navigator with this component instead
import { StatusBar as ExpoStatusBar } from 'expo-status-bar'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'

import Colors from 'theme/Colors'

interface ScreenTemplateProps {
  mode?: 'light' | 'dark'
  style?: ViewStyle
  children: ReactNode
}

export default function ScreenTemplate({
  mode = 'dark',
  children,
  style,
}: ScreenTemplateProps) {
  const insets = useSafeAreaInsets()

  const statusBarBackgroundColor =
    mode === 'dark' ? Colors.PRIMARY[800] : Colors.PRIMARY[100]

  return (
    <>
      <View
        style={{
          backgroundColor: statusBarBackgroundColor,
          paddingTop: insets.top,
        }}
      />
      <ExpoStatusBar backgroundColor="black" style="light" />

      <SafeAreaView style={[styles.content, style]} edges={['left', 'right']}>
        {children}
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
})

import React from 'react'
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native'

import { EvilIcons } from '@expo/vector-icons'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import TitleValueCard from 'components/Cards/TitleValueCard'
import HeaderBlock from 'components/HeaderBlock'
import ToggleButtonsRow, {
  ToggleButtonsRowProps,
} from 'components/Rows/ToggleButtonsRow'
interface TransactionHeaderProps extends ToggleButtonsRowProps {
  merchantName: string
  labelText: string
  leftButtonText: string
  rightButtonText: string
  titleCardText: string
  titleCardValue: string
  lastUpdatedAt?: string
  onRefetch?: () => void
}

export default function TransactionsHeader({
  merchantName,
  activeButton,
  onLeftButtonPress,
  onRefetch,
  labelText,
  leftButtonText,
  titleCardText,
  titleCardValue,
  lastUpdatedAt,
}: TransactionHeaderProps) {
  return (
    <View style={styles.headerView}>
      <HeaderBlock
        headerText={labelText}
        merchantName={merchantName}
        innerElement={
          <View style={styles.innerElementContainer}>
            <ToggleButtonsRow
              activeButton={activeButton}
              leftButtonText={leftButtonText}
              onLeftButtonPress={onLeftButtonPress}
            />
            {onRefetch && (
              <View style={styles.innerElementContainer}>
                <Text style={styles.innerText}>
                  Last refreshed{'\n'}
                  {lastUpdatedAt}
                </Text>

                <TouchableOpacity
                  onPress={onRefetch}
                  style={[styles.downloadCsvButton]}
                >
                  <EvilIcons name="refresh" size={30} color="white" />
                </TouchableOpacity>
              </View>
            )}
          </View>
        }
      >
        <TitleValueCard title={titleCardText} value={titleCardValue} />
      </HeaderBlock>
    </View>
  )
}

const styles = StyleSheet.create({
  innerElementContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Metrics.space,
  },
  innerText: {
    ...Fonts.legal,
    color: Colors.STANDARD.WHITE,
    textAlign: 'right',
  },
  downloadCsvButton: {
    backgroundColor: Colors.PRIMARY[500],
    padding: Metrics.space,
    borderRadius: Metrics.borderRadius,
    marginLeft: Metrics.space,
  },
  buttonDisabled: {
    backgroundColor: Colors.PRIMARY[200],
  },
  headerView: {
    marginBottom: Metrics.baseMargin,
  },
})

import React from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import PressableOpacity from 'components/Buttons/PressableOpacity'

export const ACTIVE_BUTTON = {
  LEFT: 'active_button_left',
  RIGHT: 'active_button_right',
}

const HITSLOP = 10

export interface ToggleButtonsRowProps {
  leftButtonText: string
  onLeftButtonPress: () => void
  activeButton: 'active_button_left' | 'active_button_right'
  style?: StyleProp<ViewStyle>
}

export default function ToggleButtonsRow({
  leftButtonText,
  onLeftButtonPress,
  style,
}: ToggleButtonsRowProps) {
  return (
    <View style={[styles.buttonsContainer, style]}>
      <PressableOpacity
        style={[styles.inactiveToggle, styles.activeToggle]}
        onPress={onLeftButtonPress}
        hitSlop={HITSLOP}
      >
        <Text style={[styles.inactiveText, styles.activeText]}>
          {leftButtonText}
        </Text>
      </PressableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    marginVertical: Metrics.mediumSpace,
  },
  rightButton: {
    marginLeft: Metrics.largeMargin,
  },
  activeToggle: {
    borderBottomColor: Colors.STANDARD.WHITE,
    borderBottomWidth: 2,
    paddingBottom: Metrics.smallSpace,
  },
  inactiveToggle: {
    paddingBottom: Metrics.smallSpace,
  },
  activeText: {
    ...Fonts.subhead3,
    color: Colors.STANDARD.WHITE,
  },
  inactiveText: {
    ...Fonts.subhead3,
    color: Colors.NEUTRAL[500],
    letterSpacing: 1,
  },
})

import React, { ReactNode } from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

import { Entypo, AntDesign, MaterialIcons } from '@expo/vector-icons'
import Ionicons from '@expo/vector-icons/Ionicons'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

export enum ICON_TYPE {
  USER = 'bx-user',
  MERCHANT = 'shop',
  CALENDAR = 'calendar',
  VOUCHER = 'ticket-outline',
  PAYMENT = 'bx-dollar-circle',
  TIME = 'time-outline',
}

interface BaseIconProps {
  iconType: ICON_TYPE
  style?: StyleProp<ViewStyle>
  color?: string
  size?: number
}

interface HeaderIconTextRowProps extends BaseIconProps {
  textStyle?: StyleProp<TextStyle>
  children: ReactNode
  allowFontScaling: boolean
}

// Return a respective icon based on the types
function getIcon({ iconType, size, color, style }: BaseIconProps) {
  const iconProps = {
    name: iconType,
    size,
    color,
    style,
  }
  switch (iconType) {
    case ICON_TYPE.USER:
      return <AntDesign name="user" size={size} color={color} />
    case ICON_TYPE.MERCHANT:
      return <Entypo {...iconProps} name="shop" />
    case ICON_TYPE.CALENDAR:
      return <Entypo name="calendar" size={size} color={color} />
    case ICON_TYPE.PAYMENT:
      return <MaterialIcons name="attach-money" size={size} color={color} />
    case ICON_TYPE.VOUCHER:
      return <MaterialCommunityIcons {...iconProps} name={iconType} />
    case ICON_TYPE.TIME:
      return <Ionicons {...iconProps} name={iconType} />
    default:
      return null
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    ...Fonts.subhead2,
    marginLeft: Metrics.smallSpace,
    flexWrap: 'wrap',
  },
  icon: {
    paddingTop: Metrics.smallSpace,
    alignSelf: 'baseline',
  },
})

export default function HeaderIconTextRow({
  iconType,
  children,
  color = Colors.STANDARD.WHITE,
  style,
  size = Metrics.icons.tiny,
  textStyle,
  allowFontScaling = true,
}: HeaderIconTextRowProps) {
  return (
    <View style={[styles.container, style]}>
      {getIcon({
        iconType,
        size,
        color,
        style: styles.icon,
      })}
      <Text
        style={[styles.text, { color }, { fontSize: size }, textStyle]}
        allowFontScaling={allowFontScaling}
        numberOfLines={1}
      >
        {children}
      </Text>
    </View>
  )
}

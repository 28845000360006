import { Platform } from 'react-native'

import { datadogRum } from '@datadog/browser-rum'

import { DatadogUser } from 'typings/datadog'

const DATADOG_SAMPLE_RATE = Number(process.env.DATADOG_SAMPLE_RATE) || 100

export function initWebMonitoring() {
  // Only allow monitoring via browser-rum for web platforms
  if (Platform.OS !== 'web') {
    return
  }

  if (
    !process.env.DATADOG_APPLICATION_ID ||
    !process.env.DATADOG_CLIENT_TOKEN
  ) {
    return
  }

  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'cbdc-merchant',
    env: process.env.APP_ENV,

    // Specify a version number to identify the deployed version of your application in Datadog
    version: '0.0.0',
    sampleRate: DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_SAMPLE_RATE,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  })

  datadogRum.startSessionReplayRecording()
}

export function setDatadogUser({ id, name }: DatadogUser) {
  datadogRum.setUser({
    id,
    name,
  })
}

// RedeemSG Color Palette
// ============================================================================
const Colors = {}

Colors.STANDARD = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
}

Colors.PRIMARY = {
  100: '#F6F7FA',
  200: '#DFE1F2',
  300: '#BCBFE3',
  400: '#868AC3',
  500: '#505798',
  600: '#3B4183',
  700: '#2F3476',
  800: '#222757',
  900: '#1B1E44',
}

Colors.SECONDARY = {
  100: '#FEF5F5',
  200: '#F7CACA',
  300: '#F2A7A7',
  400: '#ED8585',
  500: '#E86363',
  600: '#BF5151',
  700: '#994141',
  800: '#6F2F2F',
  900: '#451D1D',
}

Colors.SUCCESS = {
  100: '#F0F8F3',
  200: '#E2F1E8',
  300: '#ADD8BE',
  400: '#77BF93',
  500: '#41A669',
  600: '#0C8C3F',
  700: '#097333',
  800: '#075C29',
  900: '#05431E',
}

Colors.DANGER = {
  100: '#FFF8F8',
  200: '#F8EAEA',
  300: '#E8C1C1',
  400: '#D88888',
  500: '#C05050',
  600: '#AD4848',
  700: '#9A4040',
  800: '#733030',
  900: '#602828',
}

Colors.WARNING = {
  100: '#FDF7F0',
  200: '#FCEFE4',
  300: '#F7D2B1',
  400: '#F1B57E',
  500: '#EC984B',
  600: '#E67C18',
  700: '#BB600B',
  800: '#97510F',
  900: '#6E3B0B',
}

Colors.NEUTRAL = {
  100: '#FBFCFD',
  200: '#F0F0F1',
  300: '#E1E2E4',
  400: '#C9CCCF',
  500: '#ABADB2',
  600: '#999B9F',
  700: '#636467',
  800: '#48494B',
  900: '#242425',
}

export default Colors

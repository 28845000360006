import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

interface TransactionHeaderCardProps {
  title: string
  totalValue: string
}

export default function TransactionSectionHeaderCard({
  title,
  totalValue,
}: TransactionHeaderCardProps) {
  return (
    <>
      <View style={styles.sectionHeaderContainer}>
        <Text style={styles.sectionHeaderDateText}>{title}</Text>
        <View style={styles.sectionHeaderValueContainer}>
          <Text allowFontScaling={false} style={styles.dollarSign}>
            $
          </Text>
          <Text allowFontScaling={false} style={styles.sectionHeaderValueText}>
            {totalValue}
          </Text>
        </View>
      </View>
      <View style={styles.separatorContainer}>
        <View style={styles.separator} />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  sectionHeaderContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: Colors.STANDARD.WHITE,
    paddingHorizontal: Metrics.mediumSpace,
    marginTop: Metrics.baseMargin,
    paddingTop: Metrics.hugeSpace,
    paddingBottom: Metrics.space,
    marginHorizontal: Metrics.largeMargin,
  },
  sectionHeaderDateText: {
    ...Fonts.subhead1,
    flexWrap: 'wrap',
  },
  sectionHeaderValueText: {
    ...Fonts.h2,
    color: Colors.PRIMARY[500],
  },
  sectionHeaderValueContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dollarSign: {
    ...Fonts.subhead3,
    color: Colors.PRIMARY[500],
  },
  separator: {
    borderStyle: 'dashed',
    borderWidth: 1,
    marginHorizontal: Metrics.mediumSpace,
    borderColor: Colors.PRIMARY[300],
    backgroundColor: Colors.STANDARD.WHITE,
  },
  separatorContainer: {
    backgroundColor: Colors.STANDARD.WHITE,
    marginHorizontal: Metrics.largeMargin,
  },
})

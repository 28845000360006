import React, { useEffect, useState } from 'react'
import { StyleProp, Text, ViewStyle } from 'react-native'

import useTime from 'hooks/useTime'

// Blink rate of cursor in ms
const BLINK_RATE = 530

interface BlinkingCursorProps {
  style?: StyleProp<ViewStyle>
}

export default function BlinkingCursor({ style }: BlinkingCursorProps) {
  const [cursor, setCursor] = useState('|')
  const now = useTime(BLINK_RATE)

  // Whenever time updates, setCursor between empty string and cursor
  useEffect(() => {
    setCursor((_cursor) => (_cursor ? '' : '|'))
  }, [now])

  return <Text style={style}>{cursor}</Text>
}

import { useContract, useMutation, useProvider } from 'wagmi'

import { PBMToken } from 'typings/typechain'

import { PBM_ADDRESS } from 'constants/config'
import pbm from 'web3/abi/pbm.json'

// TODO: Move over to separate ethers service directory
const verifyAddress = async (contract: PBMToken, address: string) => {
  const role = await contract.MERCHANT_ROLE()
  const isMerchant = await contract.hasRole(role, address)
  if (!isMerchant) {
    throw new Error('Not a Merchant')
  }

  return isMerchant
}

// Reads contract RBAC properties and determines if address is merchant-eligible
// Custom hook created as wagmi is mostly query based hooks
function useAddressCheck() {
  const provider = useProvider()

  const contract = useContract<PBMToken>({
    addressOrName: PBM_ADDRESS,
    contractInterface: pbm.abi,
    signerOrProvider: provider,
  })
  return useMutation((address: string) => {
    return verifyAddress(contract, address)
  })
}

export default useAddressCheck

import React, { ReactNode } from 'react'
import {
  View,
  Text,
  StyleSheet,
  KeyboardAvoidingView,
  StyleProp,
} from 'react-native'

import moment from 'moment-timezone'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

import PrimaryButton from 'components/Buttons/PrimaryButton'
import PlainCard from 'components/Cards/PlainCard'
import CustomTextInput, {
  CustomTextInputProps,
} from 'components/CustomTextInput'
import VersionDisplay from 'components/VersionDisplay'
import ScreenTemplate from 'templates/ScreenTemplate'

moment.tz.setDefault('Asia/Singapore')

// TODO: Fix over-abstraction
type BaseRegistrationScreenProps = Pick<
  CustomTextInputProps,
  | 'invalidInputText'
  | 'fixedPlaceholder'
  | 'keyboardType'
  | 'isOtp'
  | 'onFocus'
  | 'autoFocus'
  | 'autoCapitalize'
> & {
  IllustrationImage: (style: StyleProp<any>) => JSX.Element
  children?: ReactNode
  headerText: string
  instruction: ReactNode
  primaryButtonText: string
  onPrimaryButtonPress: () => void
  isPrimaryLoading: boolean
  textInput: string
  setTextInput: (text: string) => void
  inputMaxLength?: number
  inputMinLength?: number
  topLeftButton?: ReactNode
  allowHeaderFontScaling?: boolean
  placeholder?: string
}

export default function BaseRegistrationScreen({
  IllustrationImage,
  headerText,
  placeholder,
  fixedPlaceholder,
  instruction,
  primaryButtonText,
  onPrimaryButtonPress,
  isPrimaryLoading,
  keyboardType,
  textInput,
  setTextInput,
  inputMaxLength,
  inputMinLength,
  topLeftButton,
  isOtp,
  onFocus,
  autoFocus,
  autoCapitalize,
  children,
  invalidInputText,
  allowHeaderFontScaling = true,
}: BaseRegistrationScreenProps) {
  const isPrimaryDisabled =
    textInput.length !== inputMaxLength &&
    (inputMinLength ? textInput.length < inputMinLength : true)

  return (
    <ScreenTemplate style={styles.containingView}>
      <VersionDisplay />
      <KeyboardAvoidingView
        style={styles.keyboardAvoidingView}
        behavior="padding"
      >
        <PlainCard style={styles.cardOutline}>
          {topLeftButton}
          <View style={styles.cardContentContainer}>
            <IllustrationImage style={styles.illustration} />
            <Text
              allowFontScaling={allowHeaderFontScaling}
              style={styles.textHeading}
            >
              {headerText}
            </Text>
            <Text style={styles.textInstruction}>{instruction}</Text>
            <CustomTextInput
              value={textInput}
              isOtp={isOtp}
              editable={!isPrimaryLoading}
              autoFocus={autoFocus}
              onFocus={onFocus}
              autoCapitalize={autoCapitalize}
              onChangeText={setTextInput}
              keyboardType={keyboardType}
              onSubmitEditing={() => {
                if (!isPrimaryDisabled) onPrimaryButtonPress()
              }}
              style={styles.textInput}
              fixedPlaceholder={fixedPlaceholder}
              placeholder={placeholder}
              maxLength={inputMaxLength}
              invalidInputText={invalidInputText}
            />
            {!isOtp && (
              <PrimaryButton
                onPress={onPrimaryButtonPress}
                style={styles.primaryButton}
                disabled={isPrimaryDisabled}
                isLoading={isPrimaryLoading}
              >
                {primaryButtonText}
              </PrimaryButton>
            )}
            {children}
          </View>
        </PlainCard>
      </KeyboardAvoidingView>
    </ScreenTemplate>
  )
}

const styles = StyleSheet.create({
  containingView: {
    flex: 1,
    backgroundColor: Colors.PRIMARY[100],
  },
  keyboardAvoidingView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingHorizontal: Metrics.largeSpace,
  },
  cardOutline: {
    flexGrow: 0.8,
    flexShrink: 1,
    justifyContent: 'center',
    padding: Metrics.hugeSpace,
  },
  illustration: {
    alignSelf: 'flex-start',
    marginBottom: Metrics.largeSpace,
  },
  textHeading: {
    ...Fonts.h3,
    marginBottom: Metrics.space,
  },
  textInstruction: {
    ...Fonts.subhead1,
    alignSelf: 'flex-start',
  },
  textInput: {
    marginTop: Metrics.space,
  },
  primaryButton: {
    marginTop: Metrics.largeSpace,
  },
  cardContentContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
})

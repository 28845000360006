import { Dimensions } from 'react-native'

const { width, height } = Dimensions.get('window')

const screenWidth = width < height ? width : height
const screenHeight = width < height ? height : width

const metrics = {
  tinySpace: 1,
  smallSpace: 5,
  space: 10,
  mediumSpace: 16,
  largeSpace: 20,
  hugeSpace: 28,
  section: 25,
  baseMargin: 10,
  smallMargin: 5,
  largeMargin: 20,
  inputHeight: 46,
  gradientHeight: 38,
  inputHorizontalPadding: 18,
  screenWidth,
  screenHeight,
  borderRadius: 5,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 60,
    xxl: 75,
    xxxl: 90,
  },
  hitSlop: {
    top: 10,
    left: 10,
    bottom: 10,
    right: 10,
  },
}

export default metrics

import { useQuery } from 'wagmi'

import { setDatadogUser } from 'lib/datadog'
import { fetchMerchantByAddress } from 'services/ApiService'

interface UseMerchantDetailsParams {
  address: string
}

function useMerchantDetails({ address }: UseMerchantDetailsParams) {
  return useQuery(
    ['merchant', address],
    () => fetchMerchantByAddress(address),
    {
      onError: () => {
        return null
      },
      onSuccess: (data) => {
        if (data.shopName) {
          setDatadogUser({ id: address, name: data.shopName })
        }
      },
      enabled: !!address,
    },
  )
}

export default useMerchantDetails

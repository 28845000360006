import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'

import { Ionicons } from '@expo/vector-icons'

import Colors from 'theme/Colors'
import Fonts from 'theme/Fonts'
import Metrics from 'theme/Metrics'

interface ErrorIconTextRowProps {
  children: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  color?: string
}

// TODO: Look at how to center the icon to text
export default function ErrorIconTextRow({
  children,
  style,
  textStyle,
  color,
}: ErrorIconTextRowProps) {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconContainer}>
        <Ionicons
          name="alert-circle"
          size={Metrics.icons.small}
          color={color || Colors.DANGER[500]}
        />
      </View>
      <Text style={[styles.text, textStyle, { ...(color && { color }) }]}>
        {children}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  iconContainer: {
    marginRight: Metrics.smallSpace,
  },
  text: {
    ...Fonts.body2,
    color: Colors.DANGER[500],
    flexShrink: 1,
  },
})

import { useCallback, useEffect } from 'react'

import { Listener } from '@ethersproject/providers'
import { BigNumber, Contract, ContractInterface } from 'ethers'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useContract, useProvider, useQuery } from 'wagmi'

import { TransferEventWithTimestamp } from 'typings/transaction'
import { DSGDToken } from 'typings/typechain/contracts/DSGDToken'

import { fetchMerchantDsgdTransfers } from 'services/ApiService'

interface UseTransferEventParams {
  key: string
  contractAddress: string
  abi: ContractInterface
  fromAddress?: string
  toAddress?: string
  startBlock: number
  toBlock?: number
  eventListener?: Listener
}

const useTransferEvent = ({
  key,
  contractAddress,
  abi,
  fromAddress,
  toAddress,
  eventListener,
}: UseTransferEventParams) => {
  const provider = useProvider()

  const contract = useContract<DSGDToken>({
    addressOrName: contractAddress,
    contractInterface: abi,
    signerOrProvider: provider,
  })

  const eventFilter = contract.filters.Transfer(fromAddress, toAddress)

  const { data, refetch, status, isLoading, internal, isFetching } = useQuery(
    [key, fromAddress, toAddress],
    async (): Promise<TransferEventWithTimestamp[]> => {
      const transferData = await fetchMerchantDsgdTransfers({
        fromAddress,
        toAddress,
      })

      const mappedValues = _.map(transferData.data, async (evt) => {
        return {
          ...evt,
          // React query seems to ignore args.value and caches it as an object when in local storage
          // txValue is required to explicitly cache the bigNumber value within the event's arguments
          txValue: BigNumber.from(evt.args[2]),
          timestamp: await provider.getBlock(evt.blockNumber).then((block) => {
            return moment.unix(block.timestamp).format()
          }),
        }
      })

      return (await Promise.all(mappedValues)) ?? []
    },
    {
      refetchInterval: 0,
      enabled: !!contract,
      select: useCallback((events: TransferEventWithTimestamp[]) => {
        return _.orderBy(events, 'blockNumber', 'desc')
      }, []),
    },
  )

  // update the result when ethers calls the event listener
  useEffect(() => {
    if (eventFilter != null) {
      const handler = (...event: Parameters<Contract['on']>[1][]) => {
        if (eventListener) {
          eventListener(event)
        }
        void refetch()
      }

      try {
        contract?.on(eventFilter, handler)
        return (): void => {
          contract?.off(eventFilter, handler)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [contract, eventFilter, refetch])

  return {
    data,
    refetch,
    status,
    isLoading,
    isFetching,
    dataUpdatedAt: internal.dataUpdatedAt,
  }
}

export default useTransferEvent
